import { Injectable } from '@angular/core';
import { Observable, iif, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { MeService } from './airbrush/me.service';
import { SessionService } from './session.service';
import { ListFilter, Me } from '@parashift/shared/models';

@Injectable({
  providedIn: 'root'
})
export class RefreshTokenService {

  private tenant_id: string;

  constructor(
    private meService: MeService,
    private sessionService: SessionService
  ) {}

  refresh() {
    if (this.sessionService.user_id) {
      const query_params = this.tenant_id ? { tenant_id: this.tenant_id } : {};
      const listFilter = new ListFilter({ query_params });
      this.meService.findRecord('', listFilter).subscribe((me: Me) => {
        this.sessionService.refreshGlobalToken(me.refresh_token);
        this.sessionService.refreshSessionToken(me.refresh_token);
      });
    }
  }

  get(tenant_id?: string): Observable<Me> {
    this.tenant_id = tenant_id;
    const query_params = this.tenant_id ? { tenant_id: this.tenant_id } : {};
    const listFilter = new ListFilter({ query_params });
    return of(null).pipe(
      switchMap(() => iif(() =>
        this.isSessionTokenExpired(),
        this.meService.getInitialUser(listFilter),
        this.meService.findRecord('', listFilter)
      )),
      tap(me => this.sessionService.refreshSessionToken(me.refresh_token))
    );
  }

  private isSessionTokenExpired(): boolean {
    const now = Number(Date.now().valueOf() / 1000);
    const expiresAt = Number(this.sessionService.session_token_expires_at);

    if (typeof expiresAt !== 'undefined') {
      return now >= expiresAt;
    }

    return true;
  }
}
