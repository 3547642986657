import { ElementRef, OnInit, ViewChild, Directive } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import {
  MaskitoDateMode,
  MaskitoTimeMode,
  maskitoDateOptionsGenerator,
  maskitoDateTimeOptionsGenerator,
  maskitoNumberOptionsGenerator
} from '@maskito/kit';

interface NumberConfig {
  min?: number;
  max?: number;
  precision?: number;
  decimalSeparator?: string;
  decimalPseudoSeparators?: string[];
  decimalZeroPadding?: boolean;
  thousandSeparator?: string;
  prefix?: string;
  postfix?: string;
}

interface DateConfig {
  mode: MaskitoDateMode;
  separator: string;
  max?: Date;
  min?: Date;
}

interface DateTimeConfig {
  dateMode: MaskitoDateMode;
  timeMode: MaskitoTimeMode;
  separator: string;
  max?: Date;
  min?: Date;
}

@Directive()
export abstract class InputFieldType extends FieldType<FieldTypeConfig> implements OnInit {
  @ViewChild('inputElement') readonly inputElement: ElementRef<HTMLInputElement>;

  get type() {
    return this.props.type || 'text';
  }

  ngOnInit(): void {
    if (this.field.props.mask) {

      if (this.field.props.mask === 'decimal') {
        const config: NumberConfig = {
          decimalZeroPadding: false,
          precision: this.props.decimalLimit === undefined ? 2 : this.props.decimalLimit,
          decimalSeparator: '.',
          prefix: '',
          postfix: '',
          thousandSeparator: '',
        };

        if (this.props.min) {
          config.min = this.props.min;
        }

        if (this.props.max) {
          config.max = this.props.max;
        }

        const decimalMask = maskitoNumberOptionsGenerator(config);

        this.field.props.maskConfig = decimalMask;
        // this.field.props.maskConfig = {
        //   mask: decimalMask,
        //   pipe: value => {
        //     if (!value || typeof value !== 'number' || !this.props.decimalLimit) {
        //       return value;
        //     }
        //     // add EPSILON and round to reduce rounding errors
        //     // see https://stackoverflow.com/questions/11832914/round-to-at-most-2-decimal-places-only-if-necessary
        //     // const multiplier = this.props.decimalLimit * 10;
        //     // return Math.round((value + Number.EPSILON) * multiplier / multiplier);

        //     // alternatively, use toFixed:
        //     return +value.toFixed(this.props.decimalLimit ?? 2);
        //   }
        // };

      } else if (this.field.props.mask === 'integer') {
        const config: NumberConfig = {
          decimalZeroPadding: false,
          precision: 0,
          prefix: '',
          postfix: '',
          thousandSeparator: '',
        };

        if (this.props.min) {
          config.min = this.props.min;
        }

        if (this.props.max) {
          config.max = this.props.max;
        }

        const integerMask = maskitoNumberOptionsGenerator(config);
        this.field.props.maskConfig = integerMask
      } else if (this.field.props.mask === 'date') {
        const config: DateConfig = {
          mode: 'dd/mm/yyyy',
          separator: '.'
        };

        const dateMask = maskitoDateOptionsGenerator(config);
        this.field.props.maskConfig = dateMask;
      } else if (this.field.props.mask === 'datetime') {
        const config: DateTimeConfig = {
          dateMode: 'dd/mm/yyyy',
          timeMode: 'HH:MM',
          separator: '.'
        };

        const dateTimeMask = maskitoDateTimeOptionsGenerator(config);
        this.field.props.maskConfig = dateTimeMask;
      }
    } else {
      this.field.props.maskConfig = null;
    }
  }
}
