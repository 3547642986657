import { AfterViewInit, Component, DestroyRef, Inject, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgIf, NgFor } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { Observable, Observer } from 'rxjs';
import { debounceTime, mergeMap } from 'rxjs/operators';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { DocumentTypeFieldset, DocumentTypeFieldsetStatus, DocumentTypeRelation, FilterConfig, ListFilter, Type } from '@parashift/shared/models';
import { BusinessSectorsRegister, DocumentTypeFieldsetService, DocumentTypeRelationService, ErrorResponse, JsonApiQueryData, ListFilterService, LocalesRegister, Logger, NotificationService, TagsRegister } from '@parashift/shared/services';
import { CheckPermissionsDirective, ListResultCounterDirective, ResultSwitchDirective } from '@parashift/shared/directives';
import { AvailableModels, translateModelTitles } from '@parashift/shared/utils';
import { filterColumnConfigBuilder } from './filter-column.config';
import { FilterColumnComponent } from 'shared/components/filter-column/filter-column.component';
import { EmptyResultDescriptionComponent } from 'shared/components/empty-result-description/empty-result-description.component';
import { TagBadgesComponent } from 'shared/components/badges/tag-badges/tag-badges.component';
import { BusinessSectorBadgesComponent } from 'shared/components/badges/business-sector-badges/business-sector-badges.component';
import { LocaleBadgesComponent } from 'shared/components/badges/locale-badges/locale-badges.component';
import { FilterSortingComponent } from 'shared/components/filter-sorting/filter-sorting.component';
import { FilterTagsComponent } from 'shared/components/filter-tags/filter-tags.component';
import { LeftColumnComponent } from 'shared/components/columns/left-column/left-column.component';

export interface CopyFieldsetResult {
  documentTypeFieldset: DocumentTypeFieldset;
  documentTypeRelations: DocumentTypeRelation[];
}

@Component({
  selector: 'pdc-add-fieldset-modal',
  templateUrl: './add-fieldset-modal.component.html',
  standalone: true,
  imports: [
    BusinessSectorBadgesComponent,
    CheckPermissionsDirective,
    EmptyResultDescriptionComponent,
    FaIconComponent,
    FilterColumnComponent,
    FilterSortingComponent,
    FilterTagsComponent,
    FormsModule,
    LeftColumnComponent,
    ListResultCounterDirective,
    LocaleBadgesComponent,
    NgFor,
    NgIf,
    PaginationModule,
    ReactiveFormsModule,
    ResultSwitchDirective,
    TagBadgesComponent,
    TooltipModule,
  ]
})
export class AddFieldsetModalComponent implements AfterViewInit, OnInit {
  @ViewChild(FilterColumnComponent) filterComponent: FilterColumnComponent;
  filterColumnConfig: FilterConfig;

  state: boolean;
  documentTypeFieldsets: JsonApiQueryData<DocumentTypeFieldset>;
  listFilter: ListFilter;
  documentTypeFieldsetStatus = DocumentTypeFieldsetStatus;
  tenant_id: number;
  existingFieldsetIds = [];
  fieldsetIds = [];
  fieldsetFields = {};
  fieldSetFieldRelations = {};
  type = Type;
  disableButtons = false;

  constructor(
    public bsModalRef: BsModalRef,
    private documentTypeFieldsetService: DocumentTypeFieldsetService,
    private documentTypeRelationService: DocumentTypeRelationService,
    private listFilterService: ListFilterService,
    public localesRegister: LocalesRegister,
    public businessSectorsRegister: BusinessSectorsRegister,
    public tagsRegister: TagsRegister,
    private notificationService: NotificationService,
    private logger: Logger,
    @Inject(DestroyRef) private destroyRef: DestroyRef
  ) {
    this.filterColumnConfig = filterColumnConfigBuilder(this.localesRegister, this.businessSectorsRegister, this.tagsRegister);
  }

  ngOnInit() {
    this.initListFilter();
    this.getDocumentTypeFieldsets();
  }

  ngAfterViewInit() {
    this.filterComponent.$filterParams
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        debounceTime(400)
      )
      .subscribe(listFilter => {
        this.listFilter = listFilter as ListFilter;
        this.getDocumentTypeFieldsets();
      });
  }

  addSelectedFieldset(documentTypeFieldset: DocumentTypeFieldset, documentTypeRelations: DocumentTypeRelation[]) {
    this.disableButtons = true;
    this.copyPublicFieldset(documentTypeFieldset, documentTypeRelations).subscribe({
      next: (result: CopyFieldsetResult) => {
        this.state = true;
        this.bsModalRef.content.model = { fieldset: result.documentTypeFieldset, relations: result.documentTypeRelations };
        this.bsModalRef.hide();
      },
      error: () => {
        this.notificationService.error({
          description: $localize `:@@shared_pdc.components.add_fieldset_modal.copy_fieldset_error.description:An error occured while trying to copy the fieldset. Please try again`,
          title: $localize `:@@shared_pdc.components.add_fieldset_modal.copy_fieldset_error.title:Error`
        });

        this.disableButtons = false;
      }
    });
  }

  close() {
    this.bsModalRef.hide();
  }

  alternativeAction() {
    if (this.bsModalRef.content && this.bsModalRef.content.action_alternative) {
      this.bsModalRef.content.action_alternative();
    }
    this.bsModalRef.hide();
  }

  getModelTitle(modelTitle: string, quantity: number) {
    return translateModelTitles(modelTitle as AvailableModels, quantity);
  }

  private initListFilter() {
    this.listFilter = this.listFilterService.getStoredFilters(
      this.filterColumnConfig.meta.identifier,
      {
        filter: { 'document_type_fields.discarded': 'false' },
        stats: { total: ['count'] },
        include: 'parent'
      },
      true
    );
  }

  private getDocumentTypeFieldsets() {
    this.documentTypeFieldsetService.findAll(this.listFilter, this.tenant_id).subscribe({
      next: (documentTypeFieldsets: JsonApiQueryData<DocumentTypeFieldset>) => {
        this.existingFieldsetIds = [];
        this.fieldsetIds = [];
        this.fieldsetFields = {};
        this.fieldSetFieldRelations = {};
        this.setSelectedFieldsets(documentTypeFieldsets.getModels());
        this.documentTypeFieldsets = documentTypeFieldsets;
        this.getFieldsetFields();
      },
      error: (error: ErrorResponse) => {
        this.logger.log('add-fieldset-modal-component', 'DOCUMENT TYPE FIELDSET Error', error);
      }
    });
  }

  private setSelectedFieldsets(documentTypeFieldsets: DocumentTypeFieldset[]) {
    if (
      this.bsModalRef.content.data.documentType
      && this.bsModalRef.content.data.documentType.document_type_relations
      && Array.isArray(this.bsModalRef.content.data.documentType.document_type_relations)
      && this.existingFieldsetIds.length === 0
    ) {
      documentTypeFieldsets.forEach(fieldset => {
        this.fieldsetIds.push(fieldset.id);
        this.fieldsetFields[fieldset.id] = [];
        this.fieldSetFieldRelations[fieldset.id] = [];
        if (this.bsModalRef.content.data.documentType.document_type_relations.find(rel => rel.document_type_fieldset_id === Number(fieldset.id))) {
          this.existingFieldsetIds.push(fieldset.id);
        }
      });
    }
  }

  private getFieldsetFields() {
    this.documentTypeRelationService.findAll({
      filter: {
        with_discarded: 'false',
        document_type_fieldset_id: this.fieldsetIds.join(','),
        document_type_section_id_blank: 'true'
      },
      page: { size: 1000 },
      include: 'document_type_field,parent'
    }, this.tenant_id).subscribe((documentTypeRelations: JsonApiQueryData<DocumentTypeRelation>) => {
      documentTypeRelations.getModels().forEach(relation => {
        if (!this.fieldsetFields[relation.document_type_fieldset_id]) {
          this.fieldsetFields[relation.document_type_fieldset_id] = [];
        }
        if (!this.fieldSetFieldRelations[relation.document_type_fieldset_id]) {
          this.fieldSetFieldRelations[relation.document_type_fieldset_id] = [];
        }
        this.fieldsetFields[relation.document_type_fieldset_id].push(relation.document_type_field.title);
        this.fieldSetFieldRelations[relation.document_type_fieldset_id].push(relation);
      });
    });
  }

  private copyPublicFieldset(documentTypeFieldset: DocumentTypeFieldset, documentTypeRelations: DocumentTypeRelation[]): Observable<CopyFieldsetResult> {
    return new Observable((observer: Observer<CopyFieldsetResult>) => {
      if (!documentTypeFieldset.tenant_id || documentTypeFieldset.tenant_id !== this.tenant_id) {
        const changedAttributes = {
          visibility: 'tenant',
        };

        let newFieldset: DocumentTypeFieldset;

        const request = this.documentTypeFieldsetService.inheritFrom(documentTypeFieldset.id, this.tenant_id, changedAttributes).pipe(
          mergeMap(fieldset => {
            newFieldset = fieldset;

            const relationListFilter = {
              filter: {
                with_discarded: 'false',
                document_type_fieldset_id: fieldset.id,
                document_type_section_id_blank: 'true'
              },
              page: { size: 1000 },
              include: 'document_type_field'
            };

            return this.documentTypeRelationService.findAll(relationListFilter, this.tenant_id);
          })
        );

        request.subscribe({
          next: (relations) => {
            observer.next({ documentTypeFieldset: newFieldset, documentTypeRelations: relations.getModels() });
            observer.complete();
          },
          error: () => {
            observer.error(false);
            observer.complete();
          }
        });

      } else {
        observer.next({ documentTypeFieldset, documentTypeRelations });
        observer.complete();
      }
    });
  }
}
