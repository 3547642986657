import { Component, OnInit, AfterViewInit, DestroyRef, Inject } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { InputFieldType } from 'shared/components/forms/types/input-field-type';
import { distinctUntilChanged } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormlyModule } from '@ngx-formly/core';

@Component({
    selector: 'number-percentage',
    templateUrl: './number-percentage.component.html',
    styleUrls: ['./number-percentage.component.scss'],
    standalone: true,
    imports: [ReactiveFormsModule, FormlyModule]
})
export class NumberPercentageComponent extends InputFieldType implements OnInit, AfterViewInit {
  decimalPlaces = 0;
  fieldValue: any;
  lastValue = 0;

  get type() {
    return this.props.type || 'text';
  }

  get numberPercentageFormControl() {
    return this.formControl as FormControl;
  }

  constructor(@Inject(DestroyRef) private destroyRef: DestroyRef) {
    super();
  }

  ngOnInit() {
    this.decimalPlaces = this.props.decimalPlaces || 0;
  }

  ngAfterViewInit() {
    const value = this.formControl.value;
    this.fieldValue = this.formControl.value;

    if ((this.canTransform(value)) || value === '1' || value === 1) {
      this.fieldValue = this.convertToPercentage(value);
      this.formControl.setValue(this.fieldValue);
      this.model[this.key as string] = this.fieldValue ? this.convertToFloat(this.fieldValue) : 0;
    }

    if (!value) {
      setTimeout(() => {
        const initValue = this.field.defaultValue || 0;
        this.fieldValue = this.convertToPercentage(initValue);
        this.model[this.key as string] = this.convertToFloat(initValue);
        this.formControl.setValue(this.fieldValue, { emitEvent: false, emitModelToViewChange: true });
      });
    }

    this.formControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef), distinctUntilChanged()).subscribe(newValue => {
      if (this.fieldValue !== newValue && this.lastValue !== newValue) {
        this.fieldValue = newValue;
        setTimeout(() => {
          this.model[this.key as string] = this.convertToFloat(newValue);
          this.lastValue = this.model[this.key as string];
        });
      } else {
        this.formControl.setValue(this.fieldValue, { emitEvent: false, emitModelToViewChange: true });
      }
    });
  }

  private canTransform(value: any): boolean {
    return value !== '' && value !== null && !isNaN(value);
  }

  private convertToFloat(percentageValue: number) {
    if (!this.canTransform(percentageValue)) {
      return percentageValue;
    }

    const floatValue =  parseFloat((percentageValue / 100) + '');
    const factor = Math.pow(10, this.decimalPlaces) * 100;
    const rounded = Math.round(floatValue * factor) / factor;
    return parseFloat(rounded + '');
  }

  private convertToPercentage(value) {
    const floatValue = parseFloat(value);
    if (!this.canTransform(floatValue)) {
      return floatValue;
    }

    const factor = Math.pow(10, this.decimalPlaces) * 100;
    const rounded = (Math.round(floatValue * factor) / factor);
    return +(100 * rounded).toFixed(this.decimalPlaces);
  }
}
