import * as i0 from '@angular/core';
import { inject, ElementRef, NgZone, Directive, Input, Pipe, NgModule } from '@angular/core';
import { DefaultValueAccessor } from '@angular/forms';
import { MASKITO_DEFAULT_ELEMENT_PREDICATE, maskitoTransform, Maskito, MASKITO_DEFAULT_OPTIONS } from '@maskito/core';
class MaskitoDirective {
  constructor() {
    this.elementRef = inject(ElementRef).nativeElement;
    this.ngZone = inject(NgZone);
    this.maskedElement = null;
    this.options = null;
    this.elementPredicate = MASKITO_DEFAULT_ELEMENT_PREDICATE;
    const accessor = inject(DefaultValueAccessor, {
      self: true,
      optional: true
    });
    if (accessor) {
      const original = accessor.writeValue.bind(accessor);
      accessor.writeValue = value => {
        original(this.options ? maskitoTransform(String(value ?? ''), this.options) : value);
      };
    }
  }
  async ngOnChanges() {
    const {
      elementPredicate,
      options
    } = this;
    this.maskedElement?.destroy();
    if (!options) {
      return;
    }
    const predicateResult = await elementPredicate(this.elementRef);
    if (this.elementPredicate !== elementPredicate) {
      // Ignore the result of the predicate if the
      // maskito element has changed before the predicate was resolved.
      return;
    }
    this.ngZone.runOutsideAngular(() => {
      this.maskedElement = new Maskito(predicateResult, options);
    });
  }
  ngOnDestroy() {
    this.maskedElement?.destroy();
  }
}
MaskitoDirective.ɵfac = function MaskitoDirective_Factory(t) {
  return new (t || MaskitoDirective)();
};
MaskitoDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: MaskitoDirective,
  selectors: [["", "maskito", ""]],
  inputs: {
    options: [i0.ɵɵInputFlags.None, "maskito", "options"],
    elementPredicate: [i0.ɵɵInputFlags.None, "maskitoElement", "elementPredicate"]
  },
  standalone: true,
  features: [i0.ɵɵNgOnChangesFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MaskitoDirective, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[maskito]'
    }]
  }], function () {
    return [];
  }, {
    options: [{
      type: Input,
      args: ['maskito']
    }],
    elementPredicate: [{
      type: Input,
      args: ['maskitoElement']
    }]
  });
})();
class MaskitoPipe {
  transform(value, maskitoOptions) {
    return maskitoTransform(String(value ?? ''), maskitoOptions ?? MASKITO_DEFAULT_OPTIONS);
  }
}
MaskitoPipe.ɵfac = function MaskitoPipe_Factory(t) {
  return new (t || MaskitoPipe)();
};
MaskitoPipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "maskito",
  type: MaskitoPipe,
  pure: true,
  standalone: true
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MaskitoPipe, [{
    type: Pipe,
    args: [{
      standalone: true,
      name: 'maskito'
    }]
  }], null, null);
})();

/**
 * @deprecated Just use `MaskitoDirective`, `MaskitoCVA` no longer needed.
 */
class MaskitoCVA {}
MaskitoCVA.ɵfac = function MaskitoCVA_Factory(t) {
  return new (t || MaskitoCVA)();
};
MaskitoCVA.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: MaskitoCVA,
  selectors: [["input", "maskito", ""], ["textarea", "maskito", ""]],
  inputs: {
    maskito: "maskito"
  },
  standalone: true
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MaskitoCVA, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: 'input[maskito], textarea[maskito]'
    }]
  }], null, {
    maskito: [{
      type: Input
    }]
  });
})();
/**
 * @deprecated Use standalone `MaskitoDirective` and `MaskitoPipe` instead.
 * Learn more: https://maskito.dev/frameworks/angular
 * ___
 * TODO: Delete it in v3.0 (after Taiga UI 4.0 will be released and bumped in this repository).
 * Taiga UI (3.x.x) uses Maskito (1.x.x) <=> Maskito (2.x.x) uses Taiga UI (3.x.x) to build demo application.
 * Also, NPM workspaces create symlinks for `@maskito/*` inside `node_modules`.
 * All this together don't allow us to build Maskito's demo without this legacy module.
 * ___
 * > nx build demo
 * ```
 * ./node_modules/@taiga-ui/kit/fesm2015/taiga-ui-kit-components-input-date.js:465:29-42 -
 * Error: export 'MaskitoModule' (imported as 'MaskitoModule') was not found in '@maskito/angular'
 * (possible exports: MaskitoCVA, MaskitoDirective, MaskitoPipe)
 * ```
 */
class MaskitoModule {}
MaskitoModule.ɵfac = function MaskitoModule_Factory(t) {
  return new (t || MaskitoModule)();
};
MaskitoModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: MaskitoModule
});
MaskitoModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MaskitoModule, [{
    type: NgModule,
    args: [{
      imports: [MaskitoDirective, MaskitoCVA, MaskitoPipe],
      exports: [MaskitoDirective, MaskitoCVA, MaskitoPipe]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { MaskitoCVA, MaskitoDirective, MaskitoModule, MaskitoPipe };
