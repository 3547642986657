import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Route, Router, ActivatedRouteSnapshot } from '@angular/router';
import { RefreshTokenService, SessionService } from '@parashift/shared/services';

@Injectable({
  providedIn: 'root'
})
export class MdtGuardService {

  private tokenRefreshed = false;

  constructor(
    private router: Router,
    private sessionService: SessionService,
    private refreshTokenService: RefreshTokenService,
  ) {}

  // FD: TODO: In a second step we use the permissions & roles we get from tenant (or app) dependent JWT

  canMatch(route: Route): Observable<boolean> {
    if (!this.tokenRefreshed) {
      return this.refreshTokenService.get().pipe(
        switchMap(() => {
          this.tokenRefreshed = true;
          return of(this.isUserRoleAllowed(route));
        })
      );
    } else {
      return of(true);
    }
  }

  canDeactivate(): Observable<boolean> {
    this.tokenRefreshed = false;
    return of(true);
  }

  private isUserRoleAllowed(route: Route | ActivatedRouteSnapshot): boolean {
    const userRoleAllowed = Array.isArray(route.data?.roles) && route.data?.roles.some(role => this.sessionService.global_roles.indexOf(role) >= 0);
    !userRoleAllowed && this.router.navigate(['/missing-configuration', { reason: 2 }]);
    return userRoleAllowed;
  }
}
