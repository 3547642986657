<div class="align-items-center"
     style="color: #ffffff; width: 100%; margin: 60px auto; text-align: center;">

      <svg version="1.2" class="logo-wide" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
         y="0px" width="250px" height="58px" viewBox="0 0 250 45" overflow="inherit" xml:space="preserve">
        <g>
          <polygon fill="#FFFFFF" points="7.686,29.783 7.686,1.035 36.434,1.035"/>
          <polygon fill="#FFFFFF" points="29.669,15.409 29.669,44.157 0.922,44.157"/>
        </g>
        <g>
          <path fill="#FFFFFF" d="M62.143,37.852c-2.835,0-5.445-0.719-7.29-2.924l-0.09,0.045l0.045,3.645v5.4h-4.365V10.673l3.06-0.226
            l0.9,2.79c1.935-1.979,4.725-3.149,7.964-3.149c6.57,0,10.26,3.42,10.26,13.814C72.627,33.803,68.578,37.852,62.143,37.852z
             M61.333,14.002c-2.88,0-5.13,1.125-6.525,3.15v13.769c1.035,1.756,3.15,3.285,6.3,3.285c4.185,0,7.11-2.025,7.11-10.305
            C68.218,16.118,65.833,14.002,61.333,14.002z"/>
          <path fill="#FFFFFF" d="M93.643,34.072l-0.135-0.045c-1.575,2.295-4.5,3.734-7.965,3.734c-4.905,0-8.234-2.564-8.234-8.594
            c0-5.041,3.24-8.145,9.629-8.145c2.295,0,4.5,0.449,6.165,1.034v-2.654c0-4.23-1.89-5.715-6.254-5.715
            c-2.835,0-5.13,0.495-7.515,1.62l-0.405-3.555c2.565-1.17,5.445-1.755,8.64-1.755c7.065,0,9.72,2.97,9.72,10.034v17.325h-3.24
            L93.643,34.072z M93.058,25.117c-0.675-0.404-2.655-0.99-5.354-0.99c-4.455,0-6.39,1.801-6.39,4.996
            c0,3.824,2.294,5.129,5.399,5.129c3.33,0,6.345-2.25,6.345-4.5V25.117z"/>
          <path fill="#FFFFFF" d="M104.353,37.357V10.718l3.42-0.226l0.36,5.76h0.09c1.665-3.869,4.59-6.119,8.235-6.119
            c0.99,0,1.935,0.135,2.655,0.359l-0.27,4.32c-0.81-0.271-1.71-0.36-2.655-0.36c-4.185,0-7.56,3.555-7.56,9.315v13.59H104.353z"/>
          <path fill="#FFFFFF" d="M138.643,34.072l-0.136-0.045c-1.574,2.295-4.499,3.734-7.964,3.734c-4.905,0-8.235-2.564-8.235-8.594
            c0-5.041,3.24-8.145,9.63-8.145c2.295,0,4.5,0.449,6.165,1.034v-2.654c0-4.23-1.891-5.715-6.255-5.715
            c-2.835,0-5.13,0.495-7.515,1.62l-0.405-3.555c2.565-1.17,5.445-1.755,8.64-1.755c7.064,0,9.72,2.97,9.72,10.034v17.325h-3.24
            L138.643,34.072z M138.058,25.117c-0.676-0.404-2.655-0.99-5.355-0.99c-4.454,0-6.39,1.801-6.39,4.996
            c0,3.824,2.295,5.129,5.4,5.129c3.329,0,6.345-2.25,6.345-4.5V25.117z"/>
          <path fill="#FFFFFF" d="M156.373,37.896c-3.195,0-6.165-0.764-8.235-2.25l0.54-3.689c2.025,1.529,5.085,2.34,7.65,2.34
            c3.914,0,5.534-1.891,5.534-4.41c0-6.074-13.725-3.24-13.725-12.284c0-4.545,3.195-7.515,9.36-7.515
            c2.609,0,5.084,0.54,7.244,1.529l-0.359,3.601c-2.25-0.945-4.815-1.44-6.976-1.44c-3.194,0-5.22,1.395-5.22,3.6
            c0,5.714,13.635,2.745,13.635,12.105C165.822,34.521,162.987,37.896,156.373,37.896z"/>
          <path fill="#FFFFFF" d="M188.412,37.357V19.583c0-3.6-1.936-5.58-6.075-5.58c-2.79,0-5.22,1.351-6.3,3.061v20.294h-4.229V1.853
            l4.229-0.27v7.784l-0.045,3.601l0.09,0.045c1.936-2.025,4.68-2.97,7.515-2.97c5.851,0,9.045,3.42,9.045,9.539v17.775H188.412z"/>
          <path fill="#FFFFFF" d="M202.992,6.039c-1.845,0-2.745-0.585-2.745-2.295c0-1.485,0.9-2.295,2.745-2.295s2.745,0.81,2.745,2.295
            C205.737,5.318,204.837,6.039,202.992,6.039z M200.832,37.357V10.673l4.275-0.226v26.91H200.832z"/>
          <path fill="#FFFFFF" d="M215.457,37.357V13.823h-3.959v-3.285h3.959V9.323c0-4.499,2.61-7.784,8.775-7.784
            c1.395,0,2.97,0.225,3.96,0.54l-0.271,2.925c-0.854-0.226-1.89-0.36-2.925-0.36c-3.734,0-5.265,1.755-5.265,4.725v1.17h6.885
            v3.285h-6.885v23.535H215.457z"/>
          <path fill="#FFFFFF" d="M237.821,13.868v16.47c0,2.789,1.485,3.914,3.915,3.914c0.766,0,1.71-0.135,2.25-0.359l0.271,3.24
            c-0.811,0.404-2.295,0.674-3.735,0.674c-4.59,0-6.975-2.564-6.975-7.334V13.868h-3.734v-3.33h3.869l0.811-6.659l3.329-0.36v7.02
            h6.615v3.33H237.821z"/>
        </g>
      </svg>
</div>
