import { Component, OnInit } from '@angular/core';
import { FieldArrayType, FormlyModule } from '@ngx-formly/core';

import { DocumentTypeRelation } from '@parashift/shared/models';
import {
  AddRemovedFieldsetFieldsModalComponent
} from 'components/document-type-configurator/modals/add-removed-fieldset-fields-modal/add-removed-fieldset-fields-modal.component';

import { ModalService } from 'shared/components/modals/modal.service';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'pdc-fieldset-field-section',
    templateUrl: './pdc-fieldset-field-section.component.html',
    styleUrls: ['./pdc-fieldset-field-section.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, FaIconComponent, TooltipModule, CollapseModule, FormlyModule]
})
export class PdcFieldsetFieldSectionComponent extends FieldArrayType implements OnInit {

  isCollapsed: boolean[] = [];
  lastCollapsed: number;

  constructor(private modalService: ModalService) {
    super();
  }

  ngOnInit() {
    this.model.forEach(() => this.isCollapsed.push(true));
    if (this.props.fieldId) {
      const index = this.model.findIndex(field => field.id === this.props.fieldId);

      if (index > -1) {
        this.isCollapsed[index] = false;
        this.lastCollapsed = index;
      }
    }
  }

  addFields() {
    this.modalService.customized(
      {
        initialState: {
          class: 'modal-xl',
          data: {
            document_type_fieldset_id: this.options.formState.model.id,
            fields: this.model
          },
          tenant_id: this.formState.tenant_id
        },
        ignoreBackdropClick: false,
        backdrop: true
      },
      AddRemovedFieldsetFieldsModalComponent
    ).subscribe((documentTypeRelations: DocumentTypeRelation[]) => {
      documentTypeRelations.forEach(relation => {
        this.isCollapsed.push(true);
        super.add(this.model.length, relation.document_type_field.plainModel);
      });
    });
  }

  toggleCollapsible(index: number) {
    this.isCollapsed[index] = !this.isCollapsed[index];

    if (this.lastCollapsed >= 0 && this.lastCollapsed !== index) {
      this.isCollapsed[this.lastCollapsed] = true;
    }

    this.lastCollapsed = index;
  }

  removeField(id: string, index: number) {
    const relIndex = this.field.parent.model.relations.findIndex((rel: DocumentTypeRelation) => rel.document_type_field_id === Number(id));

    if (relIndex >= -1) {
      this.field.parent.model.relations.splice(relIndex, 1);
      this.remove(index);
      this.isCollapsed.splice(index, 1);

      if (this.lastCollapsed === index) {
        this.lastCollapsed = undefined;
      }
    }
  }
}
