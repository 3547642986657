import { HttpInterceptorFn } from '@angular/common/http';

export const headerAcceptInterceptor: HttpInterceptorFn = (req, next) => {
  const accept = req.headers.get('Accept');

  if (accept === null) {
    const clonedReq = req.clone({
      setHeaders: {
        Accept: `application/vnd.api+json`
      }
    });

    return next(clonedReq);
  }

  return next(req);
};
