import { Component } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { FormlySelectOptionsPipe } from '../../pipes/select-options.pipe';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-select',
    templateUrl: './select.component.html',
    standalone: true,
    imports: [NgIf, ReactiveFormsModule, FormlyModule, NgFor, AsyncPipe, FormlySelectOptionsPipe]
})
export class SelectComponent extends FieldType<FieldTypeConfig> {
  defaultOptions = {
    props: {
      options: [],
      attributes: {
        class: 'form-control form-control-sm form-select'
      }
    }
  };

  onSelectionChange(model) {
    if (this.props.hooks && this.props.hooks.selectionChange) {
      this.props.hooks.selectionChange(model);
    }
  }

  get selectFormControl(): FormControl {
    return this.formControl as FormControl;
  }
}
