import { Injectable } from '@angular/core';
import { FormlyFieldConfig, FormlyConfig } from '@ngx-formly/core';
import { OptionProps, localizePlural } from '@parashift/shared/utils';

@Injectable({
  providedIn: 'root'
})
export class FormlyValidationTranslationService {

  constructor(private formlyConfig: FormlyConfig) {}

  init(): void {
    this.formlyConfig.addValidatorMessage('required', (err, field) => this.requiredValidationMessage(err, field));
    this.formlyConfig.addValidatorMessage('minLength', (err, field) => this.minLengthValidationMessage(err, field));
    this.formlyConfig.addValidatorMessage('maxLength', (err, field) => this.maxLengthValidationMessage(err, field));
    this.formlyConfig.addValidatorMessage('min', (err, field) => this.minValidationMessage(err, field));
    this.formlyConfig.addValidatorMessage('max', (err, field) => this.maxValidationMessage(err, field));
    this.formlyConfig.addValidatorMessage('email', (err, field) => this.emailValidationMessage(err, field));
    this.formlyConfig.addValidatorMessage('pattern', () => this.patternValidationMessage());
    this.formlyConfig.addValidatorMessage('value', () => this.valueValidationMessage());
    this.formlyConfig.addValidatorMessage('date', () => this.dateValidationMessage());
    this.formlyConfig.addValidatorMessage('datetime', () => this.datetimeValidationMessage());
    this.formlyConfig.addValidatorMessage('mime-type-format', (err) => this.mimeTypeFormatValidationMessage(err));
    this.formlyConfig.addValidatorMessage('json', () => this.jsonValidationMessage());
    this.formlyConfig.addValidatorMessage('parameter-name', () => this.parameterNameValidationMessage());
  }

  private requiredValidationMessage(err: any, field: FormlyFieldConfig) {
    const fieldLabel = field.props.label || $localize `:@@common.empty_field:This field`;
    return $localize `:@@shared.components.forms.validators.required:${fieldLabel}:field: is required`;
  }

  private minLengthValidationMessage(err: any, field: FormlyFieldConfig) {
    return $localize `:@@shared.components.forms.validators.min_length:Should have at least ${field.props.minLength}:minLength: ${
      localizePlural(field.props.minLength as OptionProps, {
        0: $localize `${field.props.minLength}:characters: characters`,
        1: $localize `${field.props.minLength}:character: character`,
        other: $localize `${field.props.minLength}:characters: characters`,
      })
    }:pluralCharacters:.`;
  }

  private maxLengthValidationMessage(err: any, field: FormlyFieldConfig) {
    return $localize `:@@shared.components.forms.validators.max_length:Should be less than ${field.props.maxLength}:maxLength: ${
      localizePlural(field.props.maxLength as OptionProps, {
        0: $localize `${field.props.maxLength}:characters: characters`,
        1: $localize `${field.props.maxLength}:character: character`,
        other: $localize `${field.props.maxLength}:characters: characters`,
      })
    }:pluralCharacters:.`;
  }

  private minValidationMessage(err: any, field: FormlyFieldConfig) {
    return $localize `:@@shared.components.forms.validators.min:This value should be more than or equal ${field.props.min}:number:`;
  }

  private maxValidationMessage(err: any, field: FormlyFieldConfig) {
    return $localize `:@@shared.components.forms.validators.max:This value should be less than or equal ${field.props.max}:number:`;
  }

  private mimeTypeFormatValidationMessage(err: any) {
    return $localize `:@@shared.components.forms.validators.mime_type_format:Please use pattern 'type/subtype[+suffix]' at line: ${err}:lineNumbers:`;
  }

  private emailValidationMessage(err: any, field: FormlyFieldConfig) {
    return $localize `:@@shared.components.forms.validators.email:${field.formControl.value}:email: is not a valid email address`;
  }

  private patternValidationMessage() {
    return $localize `:@@shared.components.forms.validators.pattern:Invalid format`;
  }

  private valueValidationMessage() {
    return $localize `:@@shared.components.forms.validators.value:Invalid value`;
  }

  private dateValidationMessage() {
    return $localize `:@@shared.components.forms.validators.date:Invalid date`;
  }

  private datetimeValidationMessage() {
    return $localize `:@@shared.components.forms.validators.datetime:Invalid date or time`;
  }

  private jsonValidationMessage() {
    return $localize `:@@shared.components.forms.validators.json:Invalid JSON`;
  }

  private parameterNameValidationMessage() {
    return $localize `:@@shared.components.forms.validators.parameter_name:Invalid parameter name`;
  }
}
