<div>
  <input class="form-control"
    type="text"
    [formControl]="tenantTypeaheadFormControl"
    [formlyAttributes]="field"
    [class.is-invalid]="showError"
    [typeahead]="dataSource"
    [typeaheadOptionsLimit]="typeaheadOptionsLimit"
    [typeaheadMinLength]="0"
    [typeaheadLatinize]="true"
    [typeaheadSelectFirstItem]="typeaheadSelectFirstItem"
    [typeaheadIsFirstItemActive]="typeaheadIsFirstItemActive"
    (typeaheadOnSelect)="onSelect($event)"
    typeaheadOptionField="searchable"
    container="body"
  >
  <fa-icon [icon]="['fas', 'university']"></fa-icon>
</div>
