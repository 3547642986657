import { Component, OnInit, ViewChild } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { TypeaheadMatch, TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { Tag } from '@parashift/shared/models';
import { TagsRegister } from '@parashift/shared/services';
import { NotificationService } from '@parashift/shared/services';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgStyle, NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'tag-typeahead',
    templateUrl: './tag-typeahead.component.html',
    standalone: true,
    imports: [NgStyle, FaIconComponent, ReactiveFormsModule, FormsModule, TypeaheadModule, NgIf, NgFor]
})
export class TagTypeaheadComponent extends FieldType<FieldTypeConfig> implements OnInit {
  @ViewChild('dropdown') dropdown: BsDropdownDirective;

  tagModels: Tag['plainModel'][] = [];
  tags: string[];
  show = false;
  tagSource: Tag['plainModel'][];
  searchTag: any;
  hideNewTag = true;

  typeaheadOptionsLimit = 10;
  typeaheadSelectFirstItem = true;
  typeaheadIsFirstItemActive = true;

  constructor(
    private tagsRegister: TagsRegister,
    private notificationService: NotificationService
  ) {
    super();
  }

  ngOnInit() {
    this.tags = (this.model && this.model.tags) || [];
    this.tagModels = this.tagsRegister.getByNames(this.tags);
    this.tagSource = this.tagsRegister.getUnusedTags(this.tagModels);

    if (this.props.typeaheadOptionsLimit) {
      this.typeaheadOptionsLimit = this.props.typeaheadOptionsLimit;
    }

    if (this.props.typeaheadSelectFirstItem !== undefined) {
      this.typeaheadSelectFirstItem = this.props.typeaheadSelectFirstItem;
    }

    if (this.props.typeaheadIsFirstItemActive !== undefined) {
      this.typeaheadIsFirstItemActive = this.props.typeaheadIsFirstItemActive;
    }
  }

  onSelectTag(event: TypeaheadMatch) {
    this.addTagToModel(event.item);
    this.resetTypeahead();
  }

  typeaheadNoResults() {
    // do nothing
  }

  resetTypeahead() {
    this.searchTag = undefined;
  }

  addNewTag() {
    const tagExists = this.tagsRegister.tagExists(this.searchTag);

    if (tagExists) {
      this.onSelectTag(new TypeaheadMatch(this.tagsRegister.getByName(this.searchTag)));
    } else {
      this.tagsRegister.addTag(this.searchTag).subscribe({
        next: (tag: Tag['plainModel']) => {
          this.addTagToModel(tag);
          this.resetTypeahead();
        },
        error: () => {
          this.resetTypeahead();
          this.notificationService.error({
            title: $localize `:@@shared.components.forms.tag_typeahead.add_tag_error.title:Error`,
            description: $localize `:@@shared.components.forms.tag_typeahead.add_tag_error.description:We could not create this tag. Please try again.`
          });
        }
      });
    }
  }

  unlinkTag(index: number, tag: Tag['plainModel']) {
    this.removeTagFromModel(index, tag);
  }

  private addTagToModel(tag: Tag['plainModel']) {
    if (!this.tags.includes(tag.name)) {
      this.tags.push(tag.name);
      this.tagModels = this.tagsRegister.getByNames(this.tags);
      this.model.tags = this.tags;
      this.tagSource = this.tagsRegister.getUnusedTags(this.tagModels);
    }
  }

  private removeTagFromModel(index: number, tag: Tag['plainModel']) {
    this.tagModels.splice(index, 1);
    const tag_index = this.tags.findIndex(item => item === tag.name);

    if (tag_index > -1) {
      this.tags.splice(tag_index, 1);
    }

    this.model.tags = this.tags;
    this.tagSource = this.tagsRegister.getUnusedTags(this.tagModels);
  }
}
