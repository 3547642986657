import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from './../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {
  constructor(private authService: AuthService, private router: Router) {}

  canMatch(): Observable<boolean> {
    return this.isAuthenticated();
  }

  canActivate(): Observable<boolean> {
    return this.isAuthenticated();
  }

  private isAuthenticated(): Observable<boolean> {
    const authState = this.authService.isAuthenticated;
    !authState && this.router.navigate(['/home']);
    return of(authState);
  }
}
