import { Component, NgIterable } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { NgFor } from '@angular/common';

@Component({
    selector: 'app-multicheckbox',
    templateUrl: './multicheckbox.component.html',
    standalone: true,
    imports: [NgFor, FormlyModule]
})
export class MulticheckboxComponent extends FieldType<FieldTypeConfig> {
  defaultOptions = {
    props: { options: [] }
  };

  get multicheckboxOptions(): NgIterable<any> {
    return this.props.options as NgIterable<any>;
  }

  onChange(value: any, target: EventTarget) {
    const checked = (<HTMLInputElement>target).checked;
    if (this.props.type === 'array') {
      this.formControl.patchValue(checked ? [...(this.formControl.value || []), value] : [...(this.formControl.value || [])].filter(o => o !== value));
    } else {
      this.formControl.patchValue({ ...this.formControl.value, [value]: checked });
    }
    this.formControl.markAsTouched();
  }
}
