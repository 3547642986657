<!-- Header -->
<div class="modal-header">

  <!-- Title -->
  <h6 class="modal-title">
    <span *ngIf="id === 'new'" i18n="@@shared_pdc.components.edit_fieldset_field_modal.title_add">Add field</span>
    <span *ngIf="id !== 'new'" i18n="@@shared_pdc.components.edit_fieldset_field_modal.title_edit">Edit field</span>
  </h6>
  <!-- Title end -->

  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()" [disabled]="editFieldsetFieldForm?.form.disabled">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<!-- Header end -->

<!-- Content -->
<div class="modal-body">
  <form *ngIf="editFieldsetFieldForm"
        [formGroup]="editFieldsetFieldForm.form"
        (ngSubmit)="saveDocumentTypeField(editFieldsetFieldForm.model)"
        #formRef="ngForm"
        id="edit-field-form"
        class="mb-3"
        appPreventEnter>
    <formly-form [form]="editFieldsetFieldForm.form"
                  [fields]="editFieldsetFieldForm.fields"
                  [model]="editFieldsetFieldForm.model"
                  [options]="editFieldsetFieldForm.options">
    </formly-form>
  </form>
</div>
<!-- Content end -->

<!-- Footer -->
<div class="modal-footer justify-content-between">
  <div class="text-start">
  </div>
  <div class="text-end">
    <button type="button"
            class="btn btn-xs btn-secondary me-1"
            (click)="close()"
            [disabled]="editFieldsetFieldForm?.form.disabled"
     i18n="@@common.cancel">Cancel</button>
    <button type="submit"
            class="btn btn-xs btn-success me-1"
            form="edit-field-form"
            [disabled]="editFieldsetFieldForm?.form.disabled"
    ><ng-container *ngIf="bsModalRef.content?.button_confirm; else default">{{ bsModalRef.content?.button_confirm }}</ng-container><ng-template #default i18n="@@common.submit">Submit</ng-template></button>
  </div>
</div>
<!-- Footer end -->
