import { registerLocaleData } from '@angular/common';
import localeDeCh from '@angular/common/locales/de-CH';
import { bootstrapApplication } from '@angular/platform-browser';
import { init } from '@sentry/angular';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { enGbLocale, deLocale } from 'ngx-bootstrap/locale';
import { config } from '@fortawesome/fontawesome-svg-core';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import * as packageJson from '../../../package.json';
import { environment } from './environments/environment';

if (environment.enable_sentry) {
  const { version } = packageJson;

  init({
    dsn: environment.sentry,
    environment: environment.env,
    release: 'app-parashift-io@' + version,
    attachStacktrace: true,
    ignoreErrors: [
      'Non-Error exception captured'
    ],
    debug: false
  });
}

registerLocaleData(localeDeCh, 'de-CH');
defineLocale('engb', enGbLocale);
defineLocale('de', deLocale);
config.autoAddCss = false;

bootstrapApplication(AppComponent, appConfig)
  .catch(err => console.error(err));
