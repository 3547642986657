<div class="display-flex">
  <div class="flex-0 p-1 align-middle pointer" (click)="model.isCollapsed = !model.isCollapsed">
    <fa-icon [icon]="['fas', 'arrow-alt-circle-down']"
             [fixedWidth]="true"
             *ngIf="model.isCollapsed">
    </fa-icon>
    <fa-icon [icon]="['fas', 'arrow-alt-circle-up']"
             [fixedWidth]="true"
             *ngIf="!model.isCollapsed">
    </fa-icon>
  </div>
  <ng-template #fieldComponent></ng-template>
  <div class="flex-1 p-1 align-middle pointer" (click)="model.isCollapsed = !model.isCollapsed">{{ model.output_data_type }}</div>
  <div class="flex-1 p-1 align-middle pointer" (click)="model.isCollapsed = !model.isCollapsed">{{ model.id }}</div>
  <div class="flex-1 p-1 align-middle pointer" (click)="model.isCollapsed = !model.isCollapsed">{{ model.root_id }}</div>

  <div class="flex-1 p-1 align-middle text-end">
    <div class="form-check form-switch">
      <input class="form-check-input"
             type="checkbox"
             [id]="'field-meta-switch-' + id"
             [disabled]="!templateField"
             [(ngModel)]="model.use_standard"
             (ngModelChange)="confirmUseStandardModal($event)">
      <label class="form-check-label pointer" [for]="'field-meta-switch-' + id"></label>
    </div>
  </div>

  <div [checkPermissions]="{ roles: ['admin', 'support', isRootField ? 'engineering' : 'sales'] }"
       class="flex-1 p-1 align-middle text-end"
       i18n-tooltip="@@shared.components.forms.document_sections_meta_head_wrapper.extraction_rules_only_for_gnn"
       [tooltip]="model.usesGnnExtractor ? undefined : 'Extraction rules editor available only for GNN extractors.'">
    <a *ngIf="isRootField else extractionRulesButton" class="btn btn-sm btn-dark position-relative"
       [class.disabled]="!model.usesGnnExtractor">
      <pp-dropdown [options]="dropdownOptions" [leftOffsetPx]="-148" [snapToRight]="true" [disableChevronAnimation]="true">
        <fa-icon [icon]="['fas', 'axe']"></fa-icon>
      </pp-dropdown>
    </a>

    <ng-template #extractionRulesButton>
      <a class="btn btn-sm btn-dark"
        [routerLink]="['extraction-rules', model.document_type_relation_id]"
        [class.disabled]="!model.usesGnnExtractor">
        <fa-icon [icon]="['fas', 'axe']"></fa-icon>
      </a>
    </ng-template>
  </div>
</div>
