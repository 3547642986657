<div class="input-group">
  <select class="form-control"
    [formControl]="selectFormControl"
    [class.form-select]="props.customSelect"
    [class.is-invalid]="showError"
    [formlyAttributes]="field"
    (ngModelChange)="onSelectionChange($event)">
    <option *ngIf="props.placeholder" [ngValue]="null">{{ props.placeholder }}</option>
    <ng-container *ngFor="let item of props.options | formlySelectOptions:field | async">
      <optgroup *ngIf="item.group" label="{{item.label}}">
        <option *ngFor="let child of item.group" [ngValue]="child.value" [disabled]="child.disabled">
          {{ child.label }}
        </option>
      </optgroup>
      <option *ngIf="!item.group" [ngValue]="item.value" [disabled]="item.disabled">{{ item.label }}</option>
    </ng-container>
  </select>
  <div class="input-group-append">
    <button [type]="props.type"
            [ngClass]="'btn ' + props.btnClass"
            (click)="onClick($event)"
            [disabled]="props.disabled">
      <fa-icon [icon]="['fas', props.icon]"
              transform="shrink-3"
              [fixedWidth]="true"
              *ngIf="props.icon">
      </fa-icon>
      <span *ngIf="props.text">{{ props.text }}</span>
    </button>
  </div>
</div>
