import { Component, HostBinding, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-message',
    templateUrl: './message.component.html',
    styleUrls: ['./message.component.scss'],
    standalone: true,
    imports: [NgIf, FaIconComponent]
})
export class MessageComponent extends FieldType<FieldTypeConfig> implements OnInit {

  @HostBinding('class.important') important = false;

  get type() {
    return this.props.type || 'message';
  }

  ngOnInit() {
    this.important = this.props.important || this.important;
  }
}
