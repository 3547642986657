import { Component } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { InputFieldType } from 'shared/components/forms/types/input-field-type';
import { FormlyModule } from '@ngx-formly/core';

@Component({
    selector: 'pdc-name-and-identifier-input',
    templateUrl: './pdc-name-and-identifier-input.component.html',
    styleUrls: ['./pdc-name-and-identifier-input.component.scss'],
    standalone: true,
    imports: [ReactiveFormsModule, FormlyModule]
})
export class PdcNameAndIdentifierComponent extends InputFieldType {
  get inputFormControl() {
    return this.formControl as FormControl;
  }
}
