<ng-container *ngIf="field.fieldGroup.length > 0 else noFields">
  <div *ngFor="let field of field.fieldGroup; let i = index;">
    <formly-field [field]="field"></formly-field>
  </div>
</ng-container>

<ng-template #noFields>
  <div class="display-flex">
    <div class="flex-5 p-1"><i i18n="@@shared.components.forms.document_type_fields_meta_list.no_fields_yet">No fields yet</i></div>
  </div>
</ng-template>
