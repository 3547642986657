<div [ngClass]="props.btnWrapperClass">
  <button [type]="props.type"
          [ngClass]="'btn ' + props.btnClass"
          (click)="onClick($event)"
          [disabled]="props.disabled">
    <fa-icon [icon]="['fas', props.icon]"
             transform="shrink-3"
             [fixedWidth]="true"
             *ngIf="props.icon">
    </fa-icon>
    <span *ngIf="props.text">{{ props.text }}</span>
  </button>
</div>
