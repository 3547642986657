import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  standalone: true
})
export class HomeComponent implements OnInit {

  constructor(private router: Router, private authService: AuthService) {}

  ngOnInit() {
    if (this.authService.isAuthenticated) {
      this.authService.handleRedirect().subscribe(redirect_to => {
        if (Array.isArray(redirect_to)) {
          this.router.navigate(redirect_to);
        } else {
          this.router.navigateByUrl(redirect_to);
        }
      });
    } else {
      this.authService.login();
    }
  }
}
