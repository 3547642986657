import { Model, Attribute, BelongsTo } from './decorators';
import { BaseApiModel } from './base';
import { WebhookEventLogType, WebhookEventType } from '@parashift/shared/types';
import { WebhookEvent } from './webhook-event.model';

export interface WebhookEventLogPlainModel {
  id: string;
  created_at: string;
  http_body: string;
  http_status: string;

  event: WebhookEvent['plainModel'] | Partial<WebhookEvent['plainModel']>;
}

@Model({ type: WebhookEventLogType })
export class WebhookEventLog extends BaseApiModel<WebhookEventLogPlainModel> {
  created_at: string;

  @Attribute()
  http_body: string;

  @Attribute()
  http_status: string;

  @BelongsTo({ class: WebhookEventType, readonly: true })
  event: WebhookEvent['plainModel'] | Partial<WebhookEvent['plainModel']>;
}
