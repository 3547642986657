<div class="form-group" [ngClass]="props.disabled ? 'disabled' : ''">
  <div class="recognition-slider">
    <ngx-slider [formControl]="sliderFormControl" [options]="sliderOptions"></ngx-slider>
  </div>

  <div class="progress mt-1">
    <div class="progress-bar bg-warning" [ngStyle]="{ 'width': warning + '%' }"></div>
    <div class="progress-bar bg-success" [ngStyle]="{ 'width': success + '%' }"></div>
  </div>
</div>
