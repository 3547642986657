import { Component, OnInit, DestroyRef, Inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { Options, LabelType, NgxSliderModule } from '@angular-slider/ngx-slider';
import { Observable } from 'rxjs';
import { NgClass, NgStyle } from '@angular/common';

export interface ReloadConfig {
  id: string;
  disabled: boolean;
}

@Component({
    selector: 'threshold-range-slider',
    templateUrl: './threshold-range-slider.component.html',
    styleUrls: ['./threshold-range-slider.component.scss'],
    standalone: true,
    imports: [NgClass, NgxSliderModule, ReactiveFormsModule, NgStyle]
})
export class ThresholdRangeSliderComponent extends FieldType<FieldTypeConfig> implements OnInit {

  danger = 0;
  warning = 0;
  success = 0;
  sliderOptions: Options;

  get sliderFormControl(): FormControl {
    return this.formControl as FormControl;
  }

  constructor(@Inject(DestroyRef) private destroyRef: DestroyRef) {
    super();
  }

  ngOnInit() {
    this.initReloadSlider();
    this.setSliderOptions();
    if (this.model.identifier && this.model.title && this.formControl.value) {
      this.setProgressValues(this.formControl.value);
    }
    this.formControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(changes => {
      this.setProgressValues(changes);
      this.setSliderOptions();
    });
  }

  initReloadSlider() {
    if (this.props.reloadSlider && this.props.reloadSlider instanceof Observable && this.props.reloadId) {
      this.props.reloadSlider.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((config: ReloadConfig) => {
        if (config.id === this.props.reloadId) {
          this.resetSliderOptions(config.disabled);
        }
      });
    }
  }

  private resetSliderOptions(disabled: boolean) {
    const newOptions: Options = Object.assign({}, this.sliderOptions);
    newOptions.disabled = disabled;
    newOptions.readOnly = disabled;
    this.sliderOptions = newOptions;
  }

  setProgressValues(thresholds: number[]) {
    const danger = thresholds[0];
    const warning = thresholds[1] - thresholds[0];
    const success = 100 - thresholds[1];

    this.danger = danger;
    this.warning = warning;
    this.success = success;
  }

  setSliderOptions() {
    this.sliderOptions = {
      disabled: this.props.disabled,
      readOnly: this.props.disabled,
      floor: 0,
      ceil: 100,
      step: 1,
      translate: (value: number, label: LabelType): string => {
        switch (label) {
          case LabelType.Low:
          case LabelType.High:
          default:
            return value + '%';
        }
      }
    };
  }
}
