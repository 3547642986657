import { Component, OnInit } from '@angular/core';
import { NgIf } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormlyModule } from '@ngx-formly/core';
import { DocumentTypeField, ListFilter } from '@parashift/shared/models';
import { ModalService } from 'shared/components/modals/modal.service';
import { PageNumberPipe } from '@parashift/shared/pipes';
import { PreventEnterDirective } from '@parashift/shared/directives';
import { EditFieldsetFieldForm } from './edit-fieldset-field.form';
import { BusinessSectorsRegister, CurrentTenantRegister, DocumentTypeFieldService, ErrorResponse, LocalesRegister, Logger, NotificationService, TransformerService, VerifierService } from '@parashift/shared/services';

@Component({
  selector: 'pdc-edit-fieldset-field-modal',
  templateUrl: './edit-fieldset-field-modal.component.html',
  standalone: true,
  imports: [NgIf, ReactiveFormsModule, PreventEnterDirective, FormlyModule]
})
export class EditFieldsetFieldModalComponent implements OnInit {

  tenant_id: number;
  id: string;
  documentTypeField: DocumentTypeField;
  editFieldsetFieldForm: EditFieldsetFieldForm;
  state: boolean;
  data: { field_id?: string; } = {};

  constructor(
    public bsModalRef: BsModalRef,
    private documentTypeFieldService: DocumentTypeFieldService,
    private verifierService: VerifierService,
    private transformerService: TransformerService,
    public localesRegister: LocalesRegister,
    public businessSectorsRegister: BusinessSectorsRegister,
    public currentTenantRegister: CurrentTenantRegister,
    public modalService: ModalService,
    private notificationService: NotificationService,
    private logger: Logger
  ) {}

  ngOnInit() {
    if (this.data.field_id && this.data.field_id !== 'new') {
      this.id = this.data.field_id;
      this.getDocumentTypeField();
    } else {
      this.id = 'new';
      this.initDocumentTypeField();
    }
  }

  saveDocumentTypeField(model: DocumentTypeField['plainModel']) {
    if (this.editFieldsetFieldForm.form.valid) {
      this.documentTypeField.plainModel = model;
      this.documentTypeField.page_number = PageNumberPipe.untransform(this.documentTypeField.page_number);

      this.documentTypeField.extraction_type_id = null;
      this.editFieldsetFieldForm.form.disable();

      this.documentTypeFieldService.saveRecord(this.documentTypeField, this.tenant_id).subscribe({
        next: (documentTypeField: DocumentTypeField) => {
          this.notificationService.success({
            description: $localize `:@@shared_pdc.components.edit_fieldset_field_modal.document_type_field_saved_description:The Document Type field has been successfully saved.`,
            title: $localize `:@@shared_pdc.components.edit_fieldset_field_modal.document_type_field_saved_title:Document type field saved`
          });
          this.state = true;
          this.bsModalRef.content.model = documentTypeField;
          this.bsModalRef.hide();

        },
        error: (error: ErrorResponse) => {
          this.editFieldsetFieldForm.form.enable();
          this.logger.log('Save Document Type Field Error', error);
          this.notificationService.formattedErrorResponse(error);
        }
      });
    }
  }

  close() {
    this.bsModalRef.hide();
  }

  private getDocumentTypeField() {
    const listFilter = new ListFilter({ include: 'extraction_type' });
    this.documentTypeFieldService.findRecord(this.id, listFilter, this.tenant_id).subscribe({
      next: (documentTypeField: DocumentTypeField) => {
        this.setDocumentTypeFieldAndForm(documentTypeField);
      },
      error: (error: ErrorResponse) => {
        this.logger.log('edit-fieldset-field-modal-component', 'DOCUMENT TYPE FIELD Error', error);
      }
    });
  }

  private initDocumentTypeField() {
    const documentTypeField = new DocumentTypeField({
      tenant_id: this.tenant_id,
      extraction_type_id: '',
      visibility: 'tenant'
    });

    this.setDocumentTypeFieldAndForm(documentTypeField);
  }

  private setDocumentTypeFieldAndForm(documentTypeField: DocumentTypeField) {
    this.documentTypeField = documentTypeField;
    const plainModel = this.documentTypeField.plainModel;
    plainModel.page_number = PageNumberPipe.transform(plainModel.page_number);

    const listFilter = new ListFilter({
      page: { number: 1, size: 200},
      sorting: { field: 'title', direction: '' }
    });

    const multiRequests = forkJoin([
      this.transformerService.findAll(listFilter),
      this.verifierService.findAll(listFilter)
    ]);

    multiRequests.subscribe({
      next: (response) => {
        this.editFieldsetFieldForm = new EditFieldsetFieldForm(
          plainModel,
          {},
          response[0].getModels(),
          response[1].getModels(),
          this.localesRegister,
          this.businessSectorsRegister,
          this.currentTenantRegister
        );
      },
      error: (error: ErrorResponse) => {
        this.logger.log('MULTI REQUESTS TRANSFORMERS & VERIFIERS Error', error);
        this.notificationService.formattedErrorResponse(error);
      }
    });
  }
}
