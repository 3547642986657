import { FilterConfig, SearchFilterConfig, SelectboxFilterConfig, SortingFilterConfig, ResetFilterConfig, MultiselectTypeaheadFilterConfig } from '@parashift/shared/models';
import { DocumentTypeFieldStatus } from '@parashift/shared/models';
import { LocalesRegister } from '@parashift/shared/services';
import { BusinessSectorsRegister } from '@parashift/shared/services';
import { TagsRegister } from '@parashift/shared/services';
import { getSelectboxOptionsFromConstants } from '@parashift/shared/utils';

export const filterColumnConfigBuilder = (localesRegister: LocalesRegister,
                                          businessSectorsRegister: BusinessSectorsRegister,
                                          tagsRegister: TagsRegister): FilterConfig => {

  return {
    meta: {
      identifier: 'pdc-add-field-modal'
    },
    filters: [
      {
        type: 'search',
        config: {
          label: $localize `:@@shared.filter_column.name:Name`,
          field: 'title_contains',
          autofocus: true
        }
      } as SearchFilterConfig,
      {
        type: 'search',
        config: {
          label: $localize `:@@shared.filter_column.identifier:Identifier`,
          field: 'identifier_contains'
        }
      } as SearchFilterConfig,
      {
        type: 'search',
        config: {
          label: 'ID',
          field: 'id'
        }
      } as SearchFilterConfig,
      {
        type: 'selectbox',
        config: {
          label: $localize `:@@common.status:Status`,
          options: getSelectboxOptionsFromConstants(DocumentTypeFieldStatus, true, undefined),
          field: 'status'
        }
      } as SelectboxFilterConfig,
      {
        type: 'multiselect-typeahead',
        config: {
          label: $localize `:@@common.locales:Locales`,
          source: localesRegister.getFilterItems(),
          sourceField: 'key',
          field: 'locales_contain',
          icon: 'flag'
        }
      } as MultiselectTypeaheadFilterConfig,
      {
        type: 'multiselect-typeahead',
        config: {
          label: $localize `:@@common.business_sectors:Business Sectors`,
          source: businessSectorsRegister.getFilterItems(),
          sourceField: 'key',
          tagDisplay: 'tagDisplay',
          field: 'business_sectors_contain',
          icon: 'suitcase',
          sliceValue: 25
        }
      } as MultiselectTypeaheadFilterConfig,
      {
        type: 'multiselect-typeahead',
        config: {
          label: $localize `:@@common.tags:Tags`,
          source: tagsRegister.getFilterItems(),
          sourceField: 'value',
          field: 'tags_contain',
          icon: 'tag',
          sliceValue: 25
        }
      } as MultiselectTypeaheadFilterConfig,
      {
        type: 'sorting',
        config: {
          label: $localize `:@@common.sorting:Sorting`,
          options: [
            { value: 'id', label: 'ID' },
            { value: 'created_at', label: $localize `:@@common.created_at:Created at` },
            { value: 'updated_at', label: $localize `:@@common.updated_at:Updated at` },
            { value: 'title', label: $localize `:@@common.name:Name` },
            { value: 'status', label: $localize `:@@common.status:Status` },
          ],
          defaultValues: {
            field: 'id',
            desc: true
          }
        }
      } as SortingFilterConfig,
      {
        type: 'reset',
        config: {
          label: $localize `:@@shared.filter_column.reset_filters:Reset Filters`
        }
      } as ResetFilterConfig
    ]
  };
};
