<div class="typeahead-with-icon default-content">
  <div class="typeahead-with-icon-search-container me-1 mb-1">
    <div class="input-group input-group-sm">
      <span class="input-group-text">
        <fa-icon [icon]="['fas', props.icon || 'circle']" [fixedWidth]="true"></fa-icon>
      </span>

      <input class="'form-control typeahead-with-icon-search mb-2'"
        type="text"
        [formControl]="multiselectTypeaheadFormControl"
        [formlyAttributes]="field"
        [class.is-invalid]="showError"
        [typeahead]="dataSource"
        [typeaheadOptionsLimit]="typeaheadOptionsLimit"
        [typeaheadMinLength]="0"
        [typeaheadLatinize]="true"
        [typeaheadSelectFirstItem]="typeaheadSelectFirstItem"
        [typeaheadIsFirstItemActive]="typeaheadIsFirstItemActive"
        [typeaheadOptionField]="props.shownOption"
        [adaptivePosition]="true"
        (typeaheadOnSelect)="onSelect($event)"
        container="body"
      >
    </div>
  </div>

  <div *ngIf="items && items.length > 0" class="mt-2">
    <ng-template ngFor let-item [ngForOf]="items" let-i="index">
      <span *ngIf="props.tooltipOption; else noTooltip" class="badge-item me-2 mb-2" tooltip="{{ item[props.tooltipOption] }}">
        <fa-icon [icon]="['fas', props.icon || 'circle']"
                [fixedWidth]="true"
                class="me-1">
        </fa-icon>{{ item[props.selectedOption] || item | slice:0:(props.sliceSelectedOption ? props.sliceSelectedOption : 200) }}<span *ngIf="props.sliceSelectedOption && (item[props.selectedOption] || item).length > (props.sliceSelectedOption || 200)">...</span><span (click)="unlinkItem(i)" class="times pointer ms-1">&times;</span>
      </span>

      <ng-template #noTooltip>
        <span class="badge-item me-2 mb-2">
          <fa-icon [icon]="['fas', props.icon || 'circle']"
                  [fixedWidth]="true"
                  class="me-1">
          </fa-icon>{{ item[props.selectedOption] || item | slice:0:(props.sliceSelectedOption ? props.sliceSelectedOption : 200)  }}<span *ngIf="props.sliceSelectedOption && (item[props.selectedOption] || item).length > (props.sliceSelectedOption || 200)">...</span><span (click)="unlinkItem(i)" class="times pointer ms-1">&times;</span>
        </span>
      </ng-template>
    </ng-template>
  </div>
</div>
