import { Component, OnInit } from '@angular/core';
import { NgIf, NgFor } from '@angular/common';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { DocumentTypeFieldStatus, Type, DocumentTypeRelation, DocumentTypeField, ListFilter } from '@parashift/shared/models';
import { JsonApiQueryData, DocumentTypeRelationService, LocalesRegister, BusinessSectorsRegister, TagsRegister, Logger, ErrorResponse } from '@parashift/shared/services';
import { ResultSwitchDirective } from '@parashift/shared/directives';
import { EmptyResultDescriptionComponent } from 'shared/components/empty-result-description/empty-result-description.component';

@Component({
  selector: 'pdc-add-removed-fieldset-fields-modal',
  templateUrl: './add-removed-fieldset-fields-modal.component.html',
  standalone: true,
  imports: [ResultSwitchDirective, NgIf, NgFor, TooltipModule, FaIconComponent, EmptyResultDescriptionComponent]
})
export class AddRemovedFieldsetFieldsModalComponent implements OnInit {
  // @ViewChild(FilterColumnComponent) filterComponent: FilterColumnComponent;
  // filterColumnConfig: FilterConfig;

  selectedRelations: DocumentTypeRelation[] = [];
  state: boolean;
  documentTypeRelations: JsonApiQueryData<DocumentTypeRelation>;
  listFilter: ListFilter;
  documentTypeFieldStatus = DocumentTypeFieldStatus;
  tenant_id: number;
  excludedFieldIds = [];
  selectedFieldIds = [];
  fieldsetFields = false;
  type = Type;
  data: {
    document_type_fieldset_id?: number,
    fields?: DocumentTypeField[]
  } = {};

  constructor(
    public bsModalRef: BsModalRef,
    private documentTypeRelationService: DocumentTypeRelationService,
    public localesRegister: LocalesRegister,
    public businessSectorsRegister: BusinessSectorsRegister,
    public tagsRegister: TagsRegister,
    private logger: Logger
  ) {
    // this.filterColumnConfig = filterColumnConfigBuilder(this.localesRegister, this.businessSectorsRegister, this.tagsRegister);
  }

  ngOnInit() {
    this.initListFilter();
    this.getDocumentTypeRelations();
  }

  // ngAfterViewInit() {
  //   this.filterComponent.$filterParams
  //     .pipe(
  //       takeUntil(this.unsubscribeCollector),
  //       debounceTime(400)
  //     )
  //     .subscribe(listFilter => {
  //       this.listFilter = <ListFilter>listFilter;
  //       this.getDocumentTypeRelations();
  //     });
  // }

  handleFieldSelection(event: any, relation: DocumentTypeRelation) {
    if (event.target.checked) {
      this.addRelation(relation);
    } else {
      this.removeRelation(relation);
    }
  }

  addSelectedFields() {
    this.state = true;
    this.bsModalRef.content.model = this.selectedRelations;
    this.bsModalRef.hide();
  }

  close() {
    this.bsModalRef.hide();
  }

  private initListFilter() {
    this.listFilter = new ListFilter({
      filter: {
        with_discarded: 'false',
        document_type_fieldset_id: this.data.document_type_fieldset_id,
        document_type_section_id_blank: 'true'
      },
      include: 'document_type_field'
    });
    // this.listFilter = this.listFilterService.getStoredFilters(
    //   this.filterColumnConfig.meta.identifier,
    //   {
    //     filter: {
    //       with_discarded: 'false',
    //       document_type_fieldset_id: this.data.config.document_type_fieldset_id,
    //       document_type_section_id_blank: 'true'
    //       // with_discarded: 'false',
    //       // document_type_fieldset_id: this.data.config.document_type_fieldset_id,
    //       // document_type_section_id: this.data.config.id,
    //       // document_type_id: this.data.config.document_type_id
    //     },
    //     include: 'document_type_field',
    //     stats: { total: ['count'] }
    //   },
    //   true
    // );
  }

  private getDocumentTypeRelations() {
    this.documentTypeRelationService.findAll(this.listFilter, this.tenant_id).subscribe({
      next: (documentTypeRelations: JsonApiQueryData<DocumentTypeRelation>) => {
        this.setExcludedFields();
        this.documentTypeRelations = documentTypeRelations;
      },
      error: (error: ErrorResponse) => this.logger.log('add-removed-fieldset-fields-modal-component', 'DOCUMENT TYPE RELATIONS Error', error)
    });
  }

  private setExcludedFields() {
    if (
      this.data.fields
      && Array.isArray(this.data.fields)
      && this.excludedFieldIds.length === 0
    ) {
      this.data.fields.forEach(field => {
        this.excludedFieldIds.push(String(field.id));
      });
    }
  }

  private addRelation(relation: DocumentTypeRelation) {
    this.selectedRelations.push(relation);
    this.selectedFieldIds.push(relation.document_type_field.id);
  }

  private removeRelation(relation: DocumentTypeRelation) {
    const index = this.selectedRelations.findIndex(rel => rel.document_type_field.id === relation.id);

    if (index > -1) {
      this.selectedRelations.splice(index, 1);
      this.selectedFieldIds.splice(index, 1);
    }
  }
}
