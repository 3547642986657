<div class="form-check" *ngFor="let option of multicheckboxOptions; let i = index;">
  <input class="form-check-input" type="checkbox"
    [id]="id + '_' + i"
    [value]="option.value"
    [checked]="formControl.value && (this.props.type === 'array' ? formControl.value.includes(option.value) : formControl.value[option.value])"
    [formlyAttributes]="field"
    (change)="onChange(option.value, $event.target)">
  <label class="form-check-label" [for]="id + '_' + i">
    {{ option.label }}
  </label>
</div>
