import { Inject, Injectable } from '@angular/core';
import { Logger } from './logger.service';
import { ENVIRONMENT_CONFIG } from '@parashift/shared/environment-config';

declare const console: any;

@Injectable()
export class ConsoleLoggerService extends Logger {

  constructor(@Inject(ENVIRONMENT_CONFIG) private environment: any){
    super();
  }

  assert(...args: any[]) {
    console && console.assert && this.environment.log && console.assert(...args);
  }

  error(...args: any[]) {
    console && console.error && this.environment.log && console.error(...args);
  }

  group(...args: any[]) {
    console && console.group && this.environment.log && console.group(...args);
  }

  groupEnd(...args: any[]) {
    console && console.groupEnd && this.environment.log && console.groupEnd(...args);
  }

  log(...args: any[]) {
    console && console.log && this.environment.log && console.log(...args);
  }

  warn(...args: any[]) {
    console && console.warn && this.environment.log && console.warn(...args);
  }
}
