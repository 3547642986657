import { Component, DestroyRef, Inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { TypeaheadMatch, TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { Observable, Observer } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { deepCopy } from '@parashift/shared/utils';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgIf, NgFor, SlicePipe } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

@Component({
    selector: 'multi-select-typeahead',
    templateUrl: './multi-select-typeahead.component.html',
    standalone: true,
    imports: [FaIconComponent, ReactiveFormsModule, FormlyModule, TypeaheadModule, NgIf, NgFor, TooltipModule, SlicePipe]
})
export class MultiSelectTypeaheadComponent extends FieldType<FieldTypeConfig> implements OnInit {

  dataSource: Observable<any>;
  typeaheadOptionsLimit = 10;
  typeaheadSelectFirstItem = true;
  typeaheadIsFirstItemActive = true;
  items = [];

  get multiselectTypeaheadFormControl() {
    return this.formControl as FormControl;
  }

  constructor(@Inject(DestroyRef) private destroyRef: DestroyRef) {
    super();
  }

  ngOnInit() {
    if (this.props.selectedModel && !this.model[this.props.selectedModel]) {
      this.model[this.props.selectedModel] = [];
    }

    this.items = this.model[this.props.selectedModel];

    if (this.props.typeaheadOptionsLimit) {
      this.typeaheadOptionsLimit = this.props.typeaheadOptionsLimit;
    }

    if (this.props.typeaheadSelectFirstItem !== undefined) {
      this.typeaheadSelectFirstItem = this.props.typeaheadSelectFirstItem;
    }

    if (this.props.typeaheadIsFirstItemActive !== undefined) {
      this.typeaheadIsFirstItemActive = this.props.typeaheadIsFirstItemActive;
    }

    this.dataSource = new Observable((observer: Observer<any>) => {
      observer.next(this.formControl);
    }).pipe(
      takeUntilDestroyed(this.destroyRef),
      debounceTime(this.props.debounceTime || 400),
      distinctUntilChanged(),
      filter(term => term !== null),
      switchMap(this.props.search || this.showMissingConfiguration)
    );
  }

  showMissingConfiguration() {
    return new Observable((observer: Observer<any[]>) => {
      observer.next(['Please configure a function search in props!']);
    });
  }

  onSelect(event: TypeaheadMatch): void {
    this.model[this.props.selectedModel] = this.model[this.props.selectedModel] || [];
    const item = deepCopy(event.item);

    if (item.search_index) {
      delete item.search_index;
    }

    if (this.props.selectedModelAttribute === undefined) {
      this.model[this.props.selectedModel].push(item);
    } else {
      this.model[this.props.selectedModel].push(event.item[this.props.selectedModelAttribute]);
    }

    this.form.get(this.key as string).setValue(null);

    if (this.props.onSelect) {
      this.props.onSelect(event, this.model, this.formControl);
    }
  }

  unlinkItem(index: number) {
    if (this.props.onUnlink) {
      this.props.onUnlink(this.model[this.props.selectedModel][index]);
    }

    this.model[this.props.selectedModel].splice(index, 1);
  }
}
