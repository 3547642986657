<div class="missing-configuration-component container" style="min-height: 100vh">
  <div class="row">
    <div class="col align-items-center">
      <div class="p-5">
        <div class="card mb-3" style="min-height: 80vh">
          <div class="card-header" i18n="@@pages.components.missing_configuration.title">
            Something went wrong...
          </div>
          <div class="card-body">
            <div class="alert alert-danger">{{ message }}</div>
            <br>
            <ng-container *ngIf="userTenants?.length > 0">
              <ng-template ngFor let-tenant [ngForOf]="userTenants">
                <div><a (click)="goToTenantPDC(tenant.id)" class="pointer">{{ tenant.name }}</a></div>
              </ng-template>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
