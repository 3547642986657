import { inject } from '@angular/core';
import { HttpInterceptorFn } from '@angular/common/http';
import { SessionService } from '@parashift/shared/services';
import { AUTHORIZATION, Authorization, AuthorizationMethod } from '@parashift/shared/constants';

export const headerAuthorizationInterceptor: HttpInterceptorFn = (req, next) => {
  const sessionService = inject(SessionService);

  const getToken = (authorization: Authorization): string => {
    switch(authorization.method) {
      case AuthorizationMethod.api_key:
        return authorization.token;
      case AuthorizationMethod.global_token:
        return authorization.token;
      default:
        return sessionService.session_token ?? sessionService.global_token;
    }
  }

  const clonedReq = req.clone({
    setHeaders: {
      Authorization: `Bearer ${getToken(req.context.get(AUTHORIZATION))}`
    }
  });

  return next(clonedReq);
};
