import { ValidationErrors } from '@angular/forms';
import { datetimeValidationError } from 'shared/components/forms/validators/datetime/datetime-validation-error';
import { datetimeValidationSuccess } from 'shared/components/forms/validators/datetime/datetime-validation-success';

interface DatetimeValidatorInput {
  readonly value: string;
}

export function DatetimeValidator({ value }: DatetimeValidatorInput): ValidationErrors {
  if (!value) {
    return datetimeValidationSuccess();
  }

  const [datePart, timePart] = value.split(' ');

  const [day, month, year] = datePart
    .split('.')
    .map(x => x.replace(/_/g, ''))
    .map(x => x === '' ? undefined : Number(x));

  const [hours, minutes] = timePart
    .split(':')
    .map(x => x.replace(/_/g, ''))
    .map(x => x === '' ? undefined : Number(x));

  if (day === undefined && month === undefined && year === undefined && hours === undefined && minutes === undefined) {
  // empty datetime
  return datetimeValidationSuccess();
  }

  if (!day || !month || !year || (!hours && hours !== 0) || (!minutes && minutes !== 0)) {
    return datetimeValidationError();
  }

  const date = new Date(year, month - 1, day, hours, minutes, 0, 0);

  return day === date.getDate()
         && month === date.getMonth() + 1
         && year === date.getFullYear()
         && date.getFullYear() >= 1900
         && hours === date.getHours()
         && minutes === date.getMinutes()
    ? datetimeValidationSuccess()
    : datetimeValidationError();
}
