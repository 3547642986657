<div class="input-group input-group-sm">
  <span
    *ngIf="props.addonLeft"
    class="input-group-text"
    [ngStyle]="{cursor: props.addonLeft.onClick ? 'pointer' : 'inherit'}"
    (click)="addonLeftClick($event)">
    <i [ngClass]="props.addonLeft.class" *ngIf="props.addonLeft.class"></i>
    <span *ngIf="props.addonLeft.icon" ><fa-icon [icon]="props.addonLeft.icon"></fa-icon></span>
    <span *ngIf="props.addonLeft.text">{{ props.addonLeft.text }}</span>
</span>
  <ng-template #fieldComponent></ng-template>
  <span class="input-group-text"
    *ngIf="props.addonRight"
    [ngStyle]="{cursor: props.addonRight.onClick ? 'pointer' : 'inherit'}"
    (click)="addonRightClick($event)">
    <i [ngClass]="props.addonRight.class" *ngIf="props.addonRight.class"></i>
    <span *ngIf="props.addonRight.icon" ><fa-icon [icon]="props.addonRight.icon"></fa-icon></span>
    <span *ngIf="props.addonRight.text">{{ props.addonRight.text  }}</span>
</span>
</div>
