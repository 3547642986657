<div>
  <h3 class="mb-3" i18n="@@common.fields">Fields</h3>
  <div class="display-flex background-headers sticky-top">
    <div class="flex-0 p-1"></div>
    <div class="flex-4 p-1 display-flex">
      <div class="flex-2 pe-1"><ng-container i18n="@@common.name">Name</ng-container>*</div>
      <div class="flex-2 px-1"><ng-container i18n="@@common.identifier">Identifier</ng-container>*</div>
    </div>
    <div class="flex-1 p-1" i18n="@@common.type">Type</div>
    <div class="flex-1 p-1">ID</div>
    <div class="flex-1 p-1" i18n="@@common.root_id">Root ID</div>
    <div class="flex-1 p-1 text-end" i18n="@@shared.components.forms.document_sections_meta_list.use_standard">Use standard</div>
    <div class="flex-1 p-1 text-end" i18n="@@common.extraction_rules" [checkPermissions]="{ roles: ['admin', 'support', 'sales'] }">Extraction rules</div>
  </div>
  <ng-container *ngFor="let field of field.fieldGroup; let i = index">
    <div class="p-1 background-section"><strong>{{ model[i].title }}</strong></div>
    <div *ngIf="model[i].fieldset" class="display-flex background-section">
      <div class="flex-0 p-1"></div>
      <div class="flex-4 p-1 display-flex">
        <div class="flex-2 pe-1">
          <div>
            <input
              type="text"
              class="form-control stealth"
              [value]="model[i].fieldset.name"
              disabled
            >
          </div>
        </div>
        <div class="flex-2 px-1">
          <div>
            <input
              type="text"
              class="form-control stealth"
              [value]="model[i].fieldset.identifier"
              readonly
            >
          </div>
        </div>
      </div>
      <div class="flex-1 p-1 align-middle"></div>
      <div class="flex-1 p-1 align-middle">{{ model[i].fieldset.id }}</div>
      <div class="flex-1 p-1 align-middle">{{ model[i].fieldset.root_id }}</div>
      <div class="flex-1 p-1 align-middle text-end"></div>
    </div>
    <div>
      <formly-field [field]="field" class="flex-1"></formly-field>
    </div>
  </ng-container>
  <div *ngIf="field.fieldGroup.length === 0" class="my-2">
    <ng-container i18n="@@shared.components.forms.document_sections_meta_list.no_fields_yet">No fields yet</ng-container>
  </div>
</div>
