<div class="form-group">
  <label *ngIf="props.label && to['hideLabel'] !== true" [class.has-error]="showError && props.required">
    {{ props.label }}
    <span *ngIf="props.required && to['hideRequiredMarker'] !== true">*</span>
  </label>
  <ng-template #fieldComponent></ng-template>
  <div *ngIf="showError" class="invalid-feedback" [style.display]="'block'">
    <formly-validation-message [style.display]="'none'" #validationRef  [field]="field"></formly-validation-message>
    <ng-container *ngIf="validationRef">
      {{ validationRef.errorMessage }}
    </ng-container>
  </div>
  <small *ngIf="props.description" class="form-text text-muted">{{ props.description }}</small>
</div>
