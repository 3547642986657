import { AfterViewInit, Component, DestroyRef, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { startWith } from 'rxjs/operators';
import { combineLatest, fromEvent } from 'rxjs';
import { FieldTokenConnectorService } from '@parashift/shared/services';
import { clamp, parashiftBlue } from '@parashift/shared/utils';

@Component({
  selector: 'app-field-token-connector',
  templateUrl: './field-token-connector.component.html',
  styleUrls: ['./field-token-connector.component.scss'],
  standalone: true
})
export class FieldTokenConnectorComponent implements OnInit, AfterViewInit {

  @Input() previewHeight: number;
  @Input() formHeight: number;

  @ViewChild('canvasElement', { read: ElementRef, static: true }) canvasElementRef: ElementRef<HTMLCanvasElement>;

  private get canvas() { return this.canvasElementRef.nativeElement; }
  private get ctx() { return this.canvas.getContext('2d'); }

  private tokenRect: DOMRect = undefined;
  private fieldRect: DOMRect = undefined;

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private fieldTokenConnectorService: FieldTokenConnectorService,
    @Inject(DestroyRef) private destroyRef: DestroyRef
  ) {}

  ngOnInit() {
    const { fieldBoundingClientRect$: fieldRect$, tokenBoundingClientRect$: tokenRect$ } = this.fieldTokenConnectorService;
    combineLatest([fieldRect$, tokenRect$]).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(([fieldRect, tokenRect]) => {
      this.tokenRect = tokenRect;
      this.fieldRect = fieldRect;
      this.redraw();
    });
  }

  ngAfterViewInit() {
    fromEvent(window, 'scroll', { capture: true }).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.fieldTokenConnectorService.notifyScroll();
    });
    this.fieldTokenConnectorService.previewResized$.pipe(takeUntilDestroyed(this.destroyRef), startWith(0)).subscribe(() => {
      this.resizeCanvas();
      this.redraw();
    });
  }

  private redraw() {
    if (this.tokenRect && this.fieldRect) {
      this.drawConnection(this.tokenRect, this.fieldRect);
    } else {
      this.clearCanvas();
    }
  }

  private drawConnection(token: DOMRect, field: DOMRect): void {
    const { tokenClippingMask, formClippingMask } = this.fieldTokenConnectorService;

    this.clearCanvas();

    if (!token || !field || !tokenClippingMask || !formClippingMask) {
      return;
    }

    const isFirstFieldInRow = !(field.left - formClippingMask.left >= 40);

    if (isFirstFieldInRow) {
      this.drawFirstFieldInRowConnection(token, field);
    } else {
      this.drawNonFirstFieldInRowConnection(token, field);
    }

    this.clip();
  }

  private drawFirstFieldInRowConnection(token: DOMRect, field: DOMRect): void {
    const { tokenClippingMask, formClippingMask } = this.fieldTokenConnectorService;
    const tokenAnchorX = token.right;
    const tokenAnchorY = (token.top + token.bottom) / 2;
    const fieldAnchorX = field.left;
    const fieldAnchorY = (field.top + field.bottom) / 2;
    const middleAnchorX = (tokenClippingMask.right + formClippingMask.left) / 2;

    this.drawLine(clamp(tokenAnchorX + 4, 0, formClippingMask.left), tokenAnchorY, middleAnchorX, tokenAnchorY);
    this.drawLine(middleAnchorX, tokenAnchorY, middleAnchorX, fieldAnchorY);
    this.drawLine(middleAnchorX, fieldAnchorY, fieldAnchorX, fieldAnchorY);
  }

  private drawNonFirstFieldInRowConnection(token: DOMRect, field: DOMRect): void {
    const { tokenClippingMask, formClippingMask } = this.fieldTokenConnectorService;
    const tokenAnchorX = token.right;
    const tokenAnchorY = (token.top + token.bottom) / 2;
    const fieldAnchorX = field.left;
    const fieldAnchorY = (field.top + field.bottom) / 2;
    const fieldIntermediateAnchorX = field.left - 6;
    const fieldIntermediateAnchorY = field.bottom + 9;
    const middleAnchorX = (tokenClippingMask.right + formClippingMask.left) / 2;

    this.drawLine(clamp(tokenAnchorX + 4, 0, formClippingMask.left), tokenAnchorY, middleAnchorX, tokenAnchorY);
    this.drawLine(middleAnchorX, tokenAnchorY, middleAnchorX, fieldIntermediateAnchorY);
    this.drawLine(middleAnchorX, fieldIntermediateAnchorY, fieldIntermediateAnchorX, fieldIntermediateAnchorY);
    this.drawLine(fieldIntermediateAnchorX, fieldIntermediateAnchorY, fieldIntermediateAnchorX, fieldAnchorY);
    this.drawLine(fieldIntermediateAnchorX, fieldAnchorY, fieldAnchorX, fieldAnchorY);
  }

  private drawLine(x1: number, y1: number, x2: number, y2: number) {
    this.ctx.strokeStyle = parashiftBlue(1);
    this.ctx.lineWidth = 2;
    this.ctx.beginPath();
    this.ctx.moveTo(x1, y1);
    this.ctx.lineTo(x2, y2);
    this.ctx.stroke();
    this.ctx.closePath();
  }

  private clip() {
    const { tokenClippingMask, formClippingMask } = this.fieldTokenConnectorService;

    this.ctx.clearRect(0, 0, this.canvas.width, tokenClippingMask.top);
    this.ctx.clearRect(0, tokenClippingMask.bottom, this.canvas.width, this.canvas.height - tokenClippingMask.bottom);

    // clip above form
    const formClippingMaskPaddingLeft = 9;
    const formClippingMaskPaddingTop = 46.6; // paddingTop is section sticky header height
    this.ctx.clearRect(
      formClippingMask.left - formClippingMaskPaddingLeft,
      0,
      this.canvas.width - formClippingMask.left + formClippingMaskPaddingLeft,
      formClippingMask.top + formClippingMaskPaddingTop);

    // clip below form
    this.ctx.clearRect(
      formClippingMask.left - formClippingMaskPaddingLeft,
      formClippingMask.bottom,
      this.canvas.width - formClippingMask.left + formClippingMaskPaddingLeft,
      this.canvas.height - formClippingMask.bottom);
  }

  private clearCanvas() {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }

  private resizeCanvas() {
    const { clientWidth: width, clientHeight: height } = this.elementRef.nativeElement;
    this.canvas.width = width;
    this.canvas.height = height;
  }
}
