import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, LOCALE_ID, importProvidersFrom } from '@angular/core';
import { APP_BASE_HREF, CurrencyPipe, DatePipe, DecimalPipe, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { provideRouter, withRouterConfig } from '@angular/router';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { BrowserModule, Title } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { provideToastr } from 'ngx-toastr';
import { AllowUnsafeHtmlInSlider } from '@angular-slider/ngx-slider';
import { FormlyModule } from '@ngx-formly/core';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { ModalModule } from 'ngx-bootstrap/modal';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import * as fuzzysort from 'fuzzysort';

import { headerAcceptInterceptor } from './interceptors/header-accept.interceptor';
import { headerContentTypeInterceptor } from './interceptors/header-content-type.interceptor';
import { headerAuthorizationInterceptor } from './interceptors/header-authorization.interceptor';
import { httpErrorInterceptor } from './interceptors/http-error.interceptor';

import { ApplicationInitService } from './services/application-init.service';
import { FormlyValidationTranslationService } from '@parashift/shared/services';
import { routes } from './app.routes';
import { formConfig } from 'shared/components/forms/form.config';
import { GlobalErrorHandler } from '@parashift/shared/services';
import { Logger } from '@parashift/shared/services';
import { ConsoleLoggerService } from '@parashift/shared/services';
import { PageNumberPipe } from '@parashift/shared/pipes';
import { PdcDatePipe } from '@parashift/shared/pipes';
import { environment } from '../environments/environment';
import { ENVIRONMENT_CONFIG } from '@parashift/shared/environment-config';

export function appInit(applicationInitService: ApplicationInitService) {
  return () => applicationInitService.handleAuthentication();
}

export function appInitFormValidationTranslation(formlyValidationService: FormlyValidationTranslationService) {
  return () => formlyValidationService.init();
}

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      BrowserModule,
      FormlyModule.forRoot({
        ...formConfig,
        extras: {
          lazyRender: false,
          resetFieldOnHide: false,
          checkExpressionOn: 'changeDetectionCheck',
          immutable: false,
        }
      }),
      BsDropdownModule.forRoot(),
      BsDatepickerModule.forRoot(),
      CodemirrorModule,
      FormsModule,
      ReactiveFormsModule,
      FormlyBootstrapModule,
      ModalModule.forRoot(),
      KeyboardShortcutsModule.forRoot(),
      FontAwesomeModule
    ),
    provideRouter(routes, withRouterConfig({ paramsInheritanceStrategy: 'always' })),
    provideHttpClient(withInterceptors([
      headerAcceptInterceptor,
      headerContentTypeInterceptor,
      headerAuthorizationInterceptor,
      httpErrorInterceptor
    ])),
    provideAnimations(),
    provideToastr({ enableHtml: true }),
    ApplicationInitService,
    Title,
    CurrencyPipe,
    DecimalPipe,
    DatePipe,
    PageNumberPipe,
    PdcDatePipe,
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: ENVIRONMENT_CONFIG, useValue: environment },
    { provide: LOCALE_ID, useValue: 'de-CH' },
    { provide: 'fuzzysort', useFactory: () => fuzzysort },
    { provide: APP_INITIALIZER, useFactory: appInit, deps: [ApplicationInitService], multi: true },
    { provide: APP_INITIALIZER, useFactory: appInitFormValidationTranslation, deps: [FormlyValidationTranslationService], multi: true },
    { provide: AllowUnsafeHtmlInSlider, useValue: false },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: Logger, useClass: ConsoleLoggerService },
  ]
};
