<ng-container *ngIf="type !== 'number' else numberTmp">
  <ng-container *ngIf="to['maskConfig'] else noMaskTmp">
    <input [type]="type"
           [formControl]="inputFormControl"
           class="form-control"
           [formlyAttributes]="field"
           [class.is-invalid]="showError"
           [maskito]="to['maskConfig']">
  </ng-container>
  <ng-template #noMaskTmp>
    <input [type]="type"
           [formControl]="inputFormControl"
           class="form-control"
           [formlyAttributes]="field"
           [class.is-invalid]="showError">
  </ng-template>
</ng-container>

<ng-template #numberTmp>
  <input type="number"
         [formControl]="inputFormControl"
         class="form-control"
         [formlyAttributes]="field"
         [class.is-invalid]="showError">
</ng-template>
