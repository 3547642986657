<div class="input-group input-group-sm datepicker-component">
  <span class="input-group-text pointer" (click)="dp.toggle()"><fa-icon [icon]="['fas', 'calendar-alt']"></fa-icon></span>

  <input class="form-control"
    type="text"
    [formControl]="datepickerFormControl"
    [formlyAttributes]="field"
    [class.is-invalid]="showError"
    [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', containerClass: 'theme-dark-blue' }"
    triggers="keydown click"
    #dp="bsDatepicker"
    bsDatepicker
    [placement]="placement">
</div>
