<div class="card form-card" [ngClass]="to['class']" [checkPermissions]="{ roles: props.permissions?.roles, permissions: props.permissions?.permissions }">
  <h3 class="card-header" *ngIf="!props.isCollapsible && props.label" [ngClass]="to['showErrors'] && cardFormControl?.invalid && form.touched ? 'text-danger' : ''">
    {{ props.label  }}
    <span *ngIf="to['showErrors'] && cardFormControl?.invalid && form.touched"><small>(<ng-container i18n="@@common.required">Required</ng-container>)</small></span>
    <span *ngIf="props.additionalText">{{ props.additionalText }}</span>
  </h3>

  <div class="card-header pointer" *ngIf="to['isCollapsible']" (click)="isCollapsed = !isCollapsed" [ngClass]="isCollapsed ? 'pb-3' : ''">
    <div class="row">
      <div class="col" [ngClass]="to['showErrors'] && cardFormControl?.invalid && form.touched ? 'text-danger' : ''">
        {{ props.label }}
        <span *ngIf="to['showErrors'] && cardFormControl?.invalid && form.touched"><small>(<ng-container i18n="@@common.required">Required</ng-container>)</small></span>
      </div>
      <div class="col-auto text-end">
        <fa-icon [icon]="['fas', 'arrow-alt-circle-down']"
                 [fixedWidth]="true"
                 *ngIf="isCollapsed">
        </fa-icon>
        <fa-icon [icon]="['fas', 'arrow-alt-circle-up']"
                 [fixedWidth]="true"
                 *ngIf="!isCollapsed">
        </fa-icon>
      </div>
    </div>
  </div>
  <div class="card-body" [collapse]="isCollapsed">
    <div *ngIf="props.description">{{ props.description }}</div>
    <ng-container #fieldComponent></ng-container>
  </div>
  <div *ngIf="to['saveButton']" class="card-footer">
    <div class="row">
      <div class="col">
        <button type="submit" class="btn btn-xs btn-success" i18n="@@common.save">
          :Save
        </button>
      </div>
    </div>
  </div>
</div>
