import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgIf, AsyncPipe } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { timer, Subscription } from 'rxjs';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { KeyboardShortcutsHelpComponent, KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { setTheme } from 'ngx-bootstrap/utils';
import { AuthService } from './services/auth.service';
import { SessionService } from '@parashift/shared/services';
import { SessionStore } from '@parashift/shared/services';
import { WhitelabellingService } from '@parashift/shared/services';
import { HistoryService } from '@parashift/shared/services';
import { CurrentUserService } from '@parashift/shared/services';
import { RefreshTokenService } from '@parashift/shared/services';
import { KeyboardShortcutService } from '@parashift/shared/services';
import { FieldTokenConnectorService } from '@parashift/shared/services';
import { OnboardingWizardService } from 'components/onboarding/services/onboarding-wizard.service';
import { TreeShakingBusterService } from './services/tree-shaking-buster.service';
import { BetaFeaturesService } from '@parashift/shared/services';
import { ModalService } from 'shared/components/modals/modal.service';
import { betaFeaturesTranslations } from '@parashift/shared/constants';
import { FieldTokenConnectorComponent } from 'components/field-token-connector/field-token-connector.component';
import { icons } from 'shared/icons/icons.library';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [RouterOutlet, KeyboardShortcutsModule, NgIf, FieldTokenConnectorComponent, AsyncPipe]
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild(KeyboardShortcutsHelpComponent, { static: true }) private keyboardHelp: KeyboardShortcutsHelpComponent;

  userSettingsInterval: Subscription;
  refreshTokenInterval: Subscription;
  urlSubscription: Subscription;

  /**
   * Initialize singleton services at the earliest possible point
   * sessionService: Must be init here in order to get a current session after reload
   * authService: Checks if session is valid or not and redirects properly
   * historyService: Must be init here in order to record the requested url before redirects get fired
   */
  constructor(
    private titleService: Title,
    private authService: AuthService,
    private sessionService: SessionService,
    private modalService: ModalService,
    private whitelabellingService: WhitelabellingService,
    private historyService: HistoryService,
    private currentUserService: CurrentUserService,
    private refreshTokenService: RefreshTokenService,
    private onboardingWizardService: OnboardingWizardService,
    private treeShakingBusterService: TreeShakingBusterService,
    private betaFeaturesService: BetaFeaturesService,
    private sessionStore: SessionStore,
    private library: FaIconLibrary,
    public shortcutService: KeyboardShortcutService,
    public fieldTokenConnectorService: FieldTokenConnectorService,
  ) {
    setTheme('bs5');
    this.library.addIcons(...icons as IconDefinition[]);
    this.treeShakingBusterService.init();
    this.listenToBetaFeatureInit();
    this.initRefreshTokenInterval();
    this.initUserSettingsInterval();

    // FD: TODO: Leave it here for now for header component refactoring. If not needed remove it.
    // this.urlSubscription = this.historyService.currentUrl$.subscribe(url => {
    //   this.setTitle(url);
    // });
  }

  ngOnInit() {
    this.setTitle();
    this.shortcutService.setKeyboardHelp(this.keyboardHelp);
    this.onboardingWizardService.listenToRoutingChanges();
    this.whitelabellingService.init();
    this.sessionStore.init();
  }

  ngOnDestroy() {
    this.userSettingsInterval.unsubscribe();
    this.refreshTokenInterval.unsubscribe();
    this.whitelabellingService.destroyListener();
  }

  private setTitle() {
    const title = 'Parashift';
    this.titleService.setTitle(title);
  }

  private initUserSettingsInterval() {
    const timerListener = timer(1000, 60000);
    this.userSettingsInterval = timerListener.subscribe(() => this.currentUserService.saveSettings());
  }

  private initRefreshTokenInterval() {
    const period = 1740000; // Refresh after 29 minutes, token is valid for 30 minutes
    const timerListener = timer(period, period);
    this.refreshTokenInterval = timerListener.subscribe(() => this.refreshTokenService.refresh());
  }

  private listenToBetaFeatureInit() {
    this.betaFeaturesService.initBetaFeatures$.subscribe((state) => state && this.checkBetaFeatures());
  }

  private checkBetaFeatures() {
    if (!this.authService.isAuthenticated) {
      return;
    }

    setTimeout(() => {
      const { all_beta_features, beta_features, beta_features_default_values } = this.sessionService;
      const userNeverDecidedOn = feature => !beta_features || Object.keys(beta_features).length === 0 || beta_features[feature] === undefined;

      (all_beta_features || []).forEach(feature => {
        if (userNeverDecidedOn(feature)) {
          if (Object.prototype.hasOwnProperty.call(beta_features_default_values, feature)) {
            this.sessionService.setBetaFeature(feature, beta_features_default_values[feature]);
            this.currentUserService.saveSettings();
          } else {
            this.modalService
            .confirmation({
              initialState: {
                description: $localize `:@@app.beta_features_modal.description:Would you like to try out our new beta feature "${ betaFeaturesTranslations[feature] }:betaFeature:" <br><br><sup>(you can change this later in your Personal Settings, accessible via the bottom-left menu)</sup>`,
                title: $localize `:@@app.beta_features_modal.title:Beta Feature: ${ betaFeaturesTranslations[feature] }:betaFeature:`,
                backdrop: true,
                emitCancelEvent: true
              }
            })
            .subscribe((flag) => {
              this.sessionService.setBetaFeature(feature, flag as boolean);
              this.currentUserService.saveSettings();
            });
          }
        }
      });
    }, 2000);
  }
}
