import { Component } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-checkbox',
    templateUrl: './checkbox.component.html',
    standalone: true,
    imports: [ReactiveFormsModule, FormlyModule, NgIf]
})
export class CheckboxComponent extends FieldType<FieldTypeConfig> {
  defaultOptions = {
    props: {
      indeterminate: true,
      hideLabel: true
    }
  };

  get checkboxFormControl() {
    return this.formControl as FormControl;
  }
}
