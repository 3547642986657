import { Component } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { InputFieldType } from 'shared/components/forms/types/input-field-type';
import { MaskitoDirective } from '@maskito/angular';
import { FormlyModule } from '@ngx-formly/core';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-input',
    templateUrl: './input.component.html',
    standalone: true,
    imports: [NgIf, ReactiveFormsModule, FormlyModule, MaskitoDirective]
})
export class InputComponent extends InputFieldType {
  get type() {
    return this.props.type || 'text';
  }

  get inputFormControl(): FormControl {
    return this.formControl as FormControl;
  }
}
