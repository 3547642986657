import { ErrorHandler, Inject, Injector, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { captureException, withScope } from '@sentry/angular';
import { ErrorResponse, JsonApiError } from '../airbrush/base';
import { ENVIRONMENT_CONFIG } from '@parashift/shared/environment-config';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  constructor(
    @Inject(Injector) private injector: Injector,
    @Inject(ENVIRONMENT_CONFIG) private environment: any
  ) {}

  private get toastrService(): ToastrService {
    return this.injector.get(ToastrService);
  }

  handleError(error: any) {
    if (this.environment.enable_sentry) {
      if (error?.status === 401 || error?.status === 'unauthorized') {
        return;
      }
      if (error instanceof ErrorResponse) {
        withScope(scope => {
          scope.setExtras(this.getJsonApiErrorData(error));
          captureException(`JSONAPI Error ${error.status}`);
        });

      } else {
        error = this.extractError(error);
        captureException(error);
      }
    } else {
      throw error;
    }

    // FD: TODO: Leave this in for Sentry round 2
    // if (error instanceof NotFoundError) {
    //   this.handleNotFoundError(error.originalError);
    // } else if (error instanceof InternalServerError) {
    //   this.handleNotFoundError(error.originalError);
    // }
  }

  private getJsonApiErrorData(error: ErrorResponse): { request_url: string, json_api_errors: JsonApiError[]} {
    return { request_url: error?.url, json_api_errors: error?.errors };
  }

  private extractError(errorCandidate: unknown): unknown {
    let error = errorCandidate;

    if (error && (error as { ngOriginalError: Error }).ngOriginalError) {
      error = (error as { ngOriginalError: Error }).ngOriginalError;
    }

    if (typeof error === 'string' || error instanceof Error) {
      return error;
    }

    if (error instanceof HttpErrorResponse) {
      if (error.error instanceof Error) {
        return error.error;
      }

      if (error.error instanceof ErrorEvent) {
        return error.error.message;
      }

      if (typeof error.error === 'string') {
        return `Server returned code ${error.status} with body "${error.error}"`;
      }

      return error.message;
    }

    return 'Handled unknown error';
  }

  private handleNotFoundError(error: HttpErrorResponse) {
    this.toastrService.error(
      error.error && error.error.exception ? error.error.exception.replace('#', '') : error.url,
      error.status + ': ' + error.statusText,
      {
        closeButton: true,
        onActivateTick: true,
        timeOut: 7000
      }
    );
  }

  private handleInternalServerError(error: HttpErrorResponse) {
    this.toastrService.error(error.url, error.status + ': ' + error.statusText, {
      closeButton: true,
      onActivateTick: true,
      timeOut: 7000
    });
  }
}
