<div class="pdc-fieldset-field-section-component">
  <div *ngIf="(field.parent.model.id && field.parent.model.id !== 'new'); else showMessage">
    <ng-container *ngFor="let field of field.fieldGroup; let i = index">
      <div class="field-header p-2" (click)="toggleCollapsible(i)">
        <div class="row">
          <div class="col pointer">
            <strong>{{ field.model.title || 'new' }}</strong>
          </div>
          <div class="col-auto text-end">
            <fa-icon
              class="pointer"
              [icon]="['fas', 'trash']"
              [fixedWidth]="true"
              (click)="removeField(field.model.id, i)"
              i18n-tooltip="@@shared.components.forms.pdc_fieldset_field_section_component.remove_field_from_fieldset"
              [tooltip]="'Remove field from fieldset'"
          ></fa-icon>
          </div>
        </div>
      </div>
      <div class="field-body" [collapse]="isCollapsed[i]">
        <formly-field [field]="field"></formly-field>
    </div>
    </ng-container>
    <div class="field-header p-2 pointer" (click)="addFields()">
      <div class="row">
        <div class="col"><strong i18n="@@shared.components.forms.pdc_fieldset_field_section_component.add_field">+ add field</strong></div>
        <div class="col-auto text-end">
          <fa-icon
            [icon]="['fas', 'plus']"
            [fixedWidth]="true"
          ></fa-icon>
        </div>
      </div>
    </div>
  </div>

  <ng-template #showMessage>
    <div class="alert alert-primary" role="alert">
      <ng-container i18n="@@shared.components.forms.pdc_fieldset_field_section_component.save_before_adding_fields">Please save this fieldset before you can add fields</ng-container>
    </div>
  </ng-template>
</div>
