<select *ngIf="props.multiple; else singleSelect" class="form-control form-select"
  multiple
  [class.form-select]="props.customSelect"
  [formControl]="selectFormControl"
  [class.is-invalid]="showError"
  [formlyAttributes]="field"
  (ngModelChange)="onSelectionChange($event)">
    <ng-container *ngFor="let item of props.options | formlySelectOptions:field | async">
      <optgroup *ngIf="item.group" label="{{item.label}}">
        <option *ngFor="let child of item.group" [ngValue]="child.value" [disabled]="child.disabled">
          {{ child.label }}
        </option>
      </optgroup>
      <option *ngIf="!item.group" [ngValue]="item.value" [disabled]="item.disabled">{{ item.label }}</option>
    </ng-container>
</select>
<ng-template #singleSelect>
  <select class="form-control form-select"
    [formControl]="selectFormControl"
    [class.form-select]="props.customSelect"
    [class.is-invalid]="showError"
    [formlyAttributes]="field"
    (ngModelChange)="onSelectionChange($event)">
    <option *ngIf="props.placeholder" [ngValue]="null">{{ props.placeholder }}</option>
    <ng-container *ngFor="let item of props.options | formlySelectOptions:field | async">
      <optgroup *ngIf="item.group" label="{{item.label}}">
        <option *ngFor="let child of item.group" [ngValue]="child.value" [disabled]="child.disabled">
          {{ child.label }}
        </option>
      </optgroup>
      <option *ngIf="!item.group" [ngValue]="item.value" [disabled]="item.disabled">{{ item.label }}</option>
    </ng-container>
  </select>
</ng-template>
