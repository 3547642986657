<div class="typeahead-with-icon default-content">

  <div class="typeahead-with-icon-search-container me-1 mb-1">
    <div class="input-group input-group-sm">
      <span class="input-group-text" [ngStyle]="{
        'background': field.props.disabled ? '#c2cfd6' : null
      }">
        <fa-icon [icon]="['fas', 'tags']" [fixedWidth]="true"></fa-icon>
      </span>
      <input type="text"
             class="form-control typeahead-with-icon-search"
             i18n="@@shared.components.forms.tag_typeahead.add_tag"
             placeholder="Add tag"
             [(ngModel)]="searchTag"
             [typeahead]="tagSource"
             [typeaheadOptionsLimit]="typeaheadOptionsLimit"
             [typeaheadSelectFirstItem]="typeaheadSelectFirstItem"
             [typeaheadIsFirstItemActive]="typeaheadIsFirstItemActive"
             [typeaheadMinLength]="1"
             [adaptivePosition]="true"
             (typeaheadOnSelect)="onSelectTag($event)"
             typeaheadOptionField="name"
             (typeaheadNoResults)="typeaheadNoResults()"
      >
      <div *ngIf="searchTag" (click)="addNewTag()" style="cursor: pointer; position: absolute; right: 3px; top: 3px; text-align: right; z-index: 5;">
        <div class="badge btn" style="background: #c2cfd6; border-radius: 0.25rem; min-width: 25px; height: 25px; display: flex; justify-content: center; align-items: center;">
          <span style="font-size: 13px; font-weight: normal; padding-right: 4px;">{{ searchTag }}</span>
          <fa-icon [icon]="['fas', 'plus']"
              [fixedWidth]="true"
              class="me-1" size="sm"></fa-icon>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="tagModels && tagModels.length > 0" class="mt-2">
    <span *ngFor="let tag of tagModels; let i = index" class="badge-item me-2 mb-2">
      <fa-icon [icon]="['fas', 'tag']"
               [fixedWidth]="true"
               class="me-1">
      </fa-icon>{{ tag.name }}<span (click)="unlinkTag(i, tag)" class="times pointer ms-1">&times;</span>
    </span>
  </div>
</div>
