<div class="form-check form-switch">
  <input class="form-check-input pointer" type="checkbox"
    [class.is-invalid]="showError"
    [formControl]="switchFormControl"
    [formlyAttributes]="field">
  <label class="form-check-label pointer" [for]="id">
    {{ props.label }}
    <span *ngIf="props.required && props.hideRequiredMarker !== true">*</span>
  </label>
</div>
