import { ChangeDetectorRef, Component, DestroyRef, Inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgIf, NgFor, NgClass } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { DndDropzoneDirective, DndDraggableDirective, DndHandleDirective, DndDragImageRefDirective } from 'ngx-drag-drop';
import { DocumentTypeSectionConfig, DocumentTypeFieldConfig } from '../document-type-config';
import { DocumentTypeRelation } from '@parashift/shared/models';
import { DocumentTypeField } from '@parashift/shared/models';
import { DocumentTypeFieldset } from '@parashift/shared/models';
import { Positioning } from '@parashift/shared/models';
import { AddFieldsModalComponent } from '../../../modals/add-fields-modal/add-fields-modal.component';
import { AddFieldsetModalComponent } from '../../../modals/add-fieldset-modal/add-fieldset-modal.component';
import { AddRemovedFieldsetFieldsModalComponent } from '../../../modals/add-removed-fieldset-fields-modal/add-removed-fieldset-fields-modal.component';
import { EditFieldsetFieldModalComponent } from '../../../modals/edit-fieldset-field-modal/edit-fieldset-field-modal.component';
import { ModalService } from 'shared/components/modals/modal.service';
import { DocumentTypeHelperService } from '../../../services/document-type-helper.service';
import { DocumentTypeConfiguratorPreviewService } from '@parashift/shared/services';
import { SessionService } from '@parashift/shared/services';
import { sortBy } from '@parashift/shared/utils';
import { groupBy } from '@parashift/shared/utils';
import { clamp } from '@parashift/shared/utils';
import { CheckPermissionsDirective } from '@parashift/shared/directives';
import { validCoordinates } from '@parashift/shared/utils';

enum DragType {
  reorder = 'reorder',
  resize = 'resize'
}

interface FieldDragStart {
  fieldId: string;
  rowIndex: number;
  fieldIndex: number;
  type: DragType;
}

interface FieldDrag {
  field: DocumentTypeFieldConfig;
  row: DocumentTypeFieldConfig[];
  rowIndex: number;
  fieldIndex: number;
  type: DragType;
}

interface FieldGripStart {
  leftColumns: number;
  rightColumns: number;
  otherColumns: number;
  clientX: number;
}

@Component({
  selector: 'document-type-section-configurator',
  templateUrl: './section-configurator.component.html',
  styleUrls: ['./section-configurator.component.scss'],
  standalone: true,
  imports: [
    CheckPermissionsDirective,
    DndDraggableDirective,
    DndDragImageRefDirective,
    DndDropzoneDirective,
    DndHandleDirective,
    FaIconComponent,
    FormsModule,
    NgClass,
    NgFor,
    NgIf,
    ReactiveFormsModule,
    TooltipModule,
  ]
})
export class DocumentTypeSectionConfiguratorComponent extends FieldType<FieldTypeConfig> implements OnInit {

  tenant_id: number;
  config: DocumentTypeSectionConfig;
  fields: DocumentTypeFieldConfig[][] = [];
  documentTypeFieldsets: DocumentTypeFieldset[] = undefined;

  dragoverRowIndex = undefined;
  dragoverFieldIndex = undefined;

  resetFieldListener = undefined;
  focusedDocumentField: DocumentTypeField;

  get draggedFieldId() { return this._draggedField && this._draggedField.id; }
  get draggedField() { return this._draggedField; }
  set draggedField(value: DocumentTypeFieldConfig) {
    this._draggedField = value;
    if (value) {
      this.draggedFieldRowIndex = this.fields.findIndex(row => row.some(field => field === value));
      const draggedRow = this.fields[this.draggedFieldRowIndex];
      this.draggedFieldIndex = draggedRow.indexOf(value);
    } else {
      this.draggedFieldRowIndex = this.draggedFieldIndex = undefined;
    }
  }
  private _draggedField: DocumentTypeFieldConfig = undefined;

  draggedFieldRowIndex = undefined;
  draggedFieldIndex = undefined;

  grippedRowColumns = undefined;
  gripStart: FieldGripStart = undefined;
  gripColumnsOffset = 0;
  resizingRowIndex = undefined;
  toggledFieldId: any;
  toggledFieldSwitched = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modalService: ModalService,
    public documentTypeHelperService: DocumentTypeHelperService,
    public sessionService: SessionService,
    private documentTypeConfiguratorPreviewService: DocumentTypeConfiguratorPreviewService,
    private cdr: ChangeDetectorRef,
    @Inject(DestroyRef) private destroyRef: DestroyRef
  ) {
    super();
    destroyRef.onDestroy(() => {
      this.documentTypeConfiguratorPreviewService.setDocTypeFieldPreview(undefined);
      this.unsubscribeResetFieldListener();
    });
  }

  ngOnInit() {
    this.route.paramMap.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(params => {
      this.tenant_id = params.get('tenant_id') ? Number(params.get('tenant_id')) : undefined;
    });
    this.config = this.model;
    this.fields = this.mapFieldsToRows(this.config.fields);
  }

  private mapFieldsToRows(fields: DocumentTypeFieldConfig[]): DocumentTypeFieldConfig[][] {
    const sorted = sortBy(fields, x => x.positioning.row);
    const grouped = groupBy(sorted, x => x.positioning.row);
    return Object.values(grouped).map(row => sortBy(row, field => field.positioning.position));
  }

  onFieldClick(field: DocumentTypeFieldConfig) {
    this.documentTypeConfiguratorPreviewService.setDocTypeFieldPreview(field.coordinates, field.page_number);
  }

  addDocumentTypeField() {
    if (this.config.fieldset_config_mode) {
      this.openEditFieldsetNewFieldModal(this.config.id);
    } else {
      this.openAddDocumentTypeRelationModal();
    }
  }

  private openAddDocumentTypeRelationModal() {
    this.modalService.customized(
      {
        initialState: {
          class: 'modal-xl fullwidth',
          action_alternative: () => this.addNewDocumentTypeField(),
          data: {
            documentType: this.formState.documentType
          },
          tenant_id: this.tenant_id
        },
        ignoreBackdropClick: false,
        backdrop: true
      },
      AddFieldsModalComponent
    ).subscribe((documentTypeFields: DocumentTypeField[]) => {
      this.addNewRelations(documentTypeFields);
    });
  }

  private addNewRelations(documentTypeFields: DocumentTypeField[]) {
    documentTypeFields.forEach(field => {
      const fieldConfig = {
        id: field.id,
        positioning: this.determineNewFieldPositioning(),
        title: field.title,
        not_for_validation: field.not_for_validation,
        optional: field.optional,
        confidence_lower_threshold: field.confidence_lower_threshold,
        confidence_upper_threshold: field.confidence_upper_threshold,
        confidence_threshold: field.plainModel.confidence_threshold,
        recognition_confidence_threshold: field.recognition_confidence_threshold,
        recognition_threshold: field.plainModel.recognition_threshold,
        not_for_training: field.not_for_training,
        validation_help: field.validation_help,
        document_type_relation_id: undefined,
        document_type_fieldset_id: undefined,
        extractionType: undefined,
        identifier: field.identifier,
        output_data_type: undefined,
        status: undefined,
        coordinates: undefined,
        page_number: undefined
      };

      this.config.fields.push(fieldConfig);
    });

    this.config.repeatable = this.config.fields.length > 1 ? false : this.config.repeatable;

    this.saveConfiguration();
  }

  private openEditFieldsetNewFieldModal(document_type_fieldset_id) {
    this.modalService.customized(
      {
        initialState: {
          class: 'modal-xl',
          button_confirm: $localize `:@@shared_pdc.components.document_type_configurator.save_and_add_field:Save & add field`,
          data: {
            field_id: undefined
          },
          tenant_id: this.tenant_id
        },
        ignoreBackdropClick: false,
        backdrop: true
      },
      EditFieldsetFieldModalComponent
    ).subscribe((documentTypeField: DocumentTypeField) => {
      const fieldConfig = {
        id: '' + documentTypeField.id,
        document_type_fieldset_id,
        positioning: this.determineNewFieldPositioning(),
        document_type_relation_id: undefined,
        identifier: undefined,
        title: undefined,
        not_for_validation: documentTypeField.not_for_validation,
        optional: documentTypeField.optional,
        confidence_lower_threshold: documentTypeField.confidence_lower_threshold,
        confidence_upper_threshold: documentTypeField.confidence_upper_threshold,
        confidence_threshold: documentTypeField.plainModel.confidence_threshold,
        recognition_confidence_threshold: documentTypeField.recognition_confidence_threshold,
        recognition_threshold: documentTypeField.plainModel.recognition_threshold,
        not_for_training: documentTypeField.not_for_training,
        validation_help: documentTypeField.validation_help,
        extractionType: undefined,
        output_data_type: undefined,
        status: undefined,
        coordinates: undefined,
        page_number: undefined
      };

      this.config.fields.push(fieldConfig);
      this.saveConfiguration();
    });
  }

  deleteDocumentTypeField(relation_id) {
    this.openDeleteDocumentTypeRelationModal(relation_id);
  }

  openDeleteDocumentTypeRelationModal(relation_id) {
    this.modalService
      .confirmation({
        initialState: {
          description: $localize `:@@shared_pdc.components.document_type_configurator.document_type_field_delete_description:Do you want to delete this Document Type field?`,
          title: $localize `:@@shared_pdc.components.document_type_configurator.document_type_field_delete_title:Delete Document Type field`
        }
      })
      .subscribe(() => {
        const field = this.config.fields.find(x => x.document_type_relation_id === relation_id);
        const fieldIndex = this.config.fields.findIndex(x => x.document_type_relation_id === relation_id);

        this.config.fields.splice(fieldIndex, 1);

        const remainingFieldsInRow = this.config.fields.filter(f => f.positioning.row === field.positioning.row);

        if (remainingFieldsInRow.length > 0) {
          let currentPos = 0;

          this.config.fields = this.config.fields.map(f => {
            if (f.positioning.row !== field.positioning.row) {
              return f;
            } else {
              return {...f,
                positioning: {...f.positioning,
                  columns: 12 / remainingFieldsInRow.length,
                  row: field.positioning.row,
                  position: currentPos++
                }
              };
            }
          });
        }

        this.saveConfiguration();
      });
  }

  private openEditFieldsetFieldModal(fieldId: string) {
    this.modalService.customized(
      {
        initialState: {
          class: 'modal-xl',
          button_confirm: $localize `:@@shared_pdc.components.document_type_configurator.save_field_changes:Save field changes`,
          data: {
            field_id: fieldId
          },
          tenant_id: this.tenant_id
        },
        ignoreBackdropClick: false,
        backdrop: true
      },
      EditFieldsetFieldModalComponent
    ).subscribe((documentTypeField: DocumentTypeField) => {
      const fieldConfig = this.config.fields.find(config => config.id === documentTypeField.id);

      if (fieldConfig) {
        fieldConfig.identifier = documentTypeField.identifier;
        fieldConfig.title = documentTypeField.title;
        fieldConfig.not_for_validation = documentTypeField.not_for_validation;
        fieldConfig.optional = documentTypeField.optional;
        fieldConfig.confidence_lower_threshold = documentTypeField.confidence_lower_threshold;
        fieldConfig.confidence_upper_threshold = documentTypeField.confidence_upper_threshold;
        fieldConfig.confidence_threshold = documentTypeField.plainModel.confidence_threshold;
        fieldConfig.recognition_confidence_threshold = documentTypeField.recognition_confidence_threshold;
        fieldConfig.recognition_threshold = documentTypeField.plainModel.recognition_threshold;
        fieldConfig.not_for_training = documentTypeField.not_for_training;
        fieldConfig.validation_help = documentTypeField.validation_help;
      }

      this.saveConfiguration();
    });
  }


  configureField(field: DocumentTypeFieldConfig) {
    const document_type_section_id = this.model.id;
    const document_id = this.formState.model.document_id;

    if (this.config.fieldset_config_mode) {
      this.openEditFieldsetFieldModal(field.id);

    } else if (field.document_type_fieldset_id) {
      const params = {
        ...{ document_type_section_id },
        ...(document_id ? { document_id } : {}),
        ...{ field_id: field.id }
      };

      this.router.navigate(
        ['.', params],
        { queryParams: { 'edit-fieldset': field.document_type_fieldset_id }, relativeTo: this.route, queryParamsHandling: 'merge' }
      );
    } else {
      const params = {
        ...{ document_type_section_id },
        ...(document_id ? { document_id } : {})
      };

      this.router.navigate(['.', params], { queryParams: { 'edit-field': field.id }, relativeTo: this.route, queryParamsHandling: 'merge' });
    }
  }

  repeatableStateChanged(value: boolean) {
    this.config.show_as_table = value;
    value && this.highlightRowStateChanged(true);
    value && this.highlightColumnStateChanged(true);
  }

  highlightRowStateChanged(value: boolean) {
    this.config.highlight_row = value;
  }

  highlightColumnStateChanged(value: boolean) {
    this.config.highlight_column = value;
  }

  saveConfiguration() {
    if (this.props.hooks && this.props.hooks.saveConfiguration) {
      this.props.hooks.saveConfiguration();
    }
  }

  private reloadDocumentType() {
    if (this.props.hooks && this.props.hooks.reload) {
      this.props.hooks.reload();
    }
  }

  addNewDocumentTypeField() {
    const document_id = this.formState.model.document_id;
    const document_type_section_id = this.model.id;
    const params = {
      ...{ document_type_section_id },
      ...(document_id ? { document_id } : {})
    };
    this.router.navigate(['.', params], { queryParams: { 'edit-field': 'new' }, relativeTo: this.route, queryParamsHandling: 'merge' });
  }

  addNewDocumentTypeFieldset() {
    const document_type_id = this.formState.model.id;
    const document_type_section_id = this.model.id;
    const document_id = this.formState.model.document_id;
    const params = {
      ...{ document_type_section_id },
      ...(document_type_id ? { document_type_id } : {}),
      ...(document_id ? { document_id } : {})
    };

    this.router.navigate(['../../../fieldsets/edit/new', params], { relativeTo: this.route, queryParamsHandling: 'merge' });
  }

  onFieldDragStart($event: DragEvent, field: DocumentTypeFieldConfig) {
    this.draggedField = field;
    this.cdr.detectChanges();
    setTimeout(() => {
      this.resetToggledField();
    });
  }

  onFieldDragover($event: DragEvent, rowIndex: number, fieldIndex: number) {
    this.dragoverRowIndex = rowIndex;
    this.dragoverFieldIndex = fieldIndex;
    this.cdr.detectChanges();
  }

  private determineNewFieldPositioning(): Positioning {
    const lastRowIndex = this.model.fields.reduce((max, field) => {
      const row = field.positioning.row;
      return row > max ? row : max;
    }, 0);
    return {
      row: lastRowIndex + 1,
      columns: 12,
      position: 0
    };
  }

  onFieldDragEnd() {
    this.dragoverRowIndex = undefined;
    this.dragoverFieldIndex = undefined;
    this.draggedField = undefined;
    this.cdr.detectChanges();
  }

  insertRowBefore(event: FieldDragStart, targetRowIndex: number) {
    const data = this.deserializeFieldDragStartData(event);
    if (data.type === DragType.resize) {
      return;
    }

    this.removeField(data);
    targetRowIndex = clamp(targetRowIndex, 0, this.fields.length);
    this.fields.splice(targetRowIndex, 0, [data.field]);
    this.updatePositions(data, targetRowIndex);
    this.finishDragAndDrop();
  }

  insertRowAfter(event: FieldDragStart, targetRowIndex: number) {
    const data = this.deserializeFieldDragStartData(event);
    if (data.type === DragType.resize) {
      return;
    }

    this.removeField(data);
    targetRowIndex = clamp(targetRowIndex + 1, 0, this.fields.length);

    this.fields.splice(targetRowIndex, 0, [data.field]);

    this.updatePositions(data, targetRowIndex);
    this.finishDragAndDrop();
  }

  insertFieldBefore(event: FieldDragStart, rowIndex: number, fieldIndex: number) {
    const data = this.deserializeFieldDragStartData(event);
    if (data.type === DragType.resize) {
      return;
    }

    this.removeField(data);

    const row = this.fields[rowIndex];
    row.splice(fieldIndex, 0, data.field);

    this.updatePositions(data, rowIndex);
    this.finishDragAndDrop();
  }

  insertFieldAfter(event: FieldDragStart, rowIndex: number, fieldIndex: number) {
    const data = this.deserializeFieldDragStartData(event);
    if (data.type === DragType.resize) {
      return;
    }

    const row = this.fields[rowIndex];
    fieldIndex = clamp(fieldIndex + 1, 0, row.length);
    row.splice(fieldIndex, 0, data.field);

    this.removeField(data);

    this.updatePositions(data, rowIndex);
    this.finishDragAndDrop();
  }

  private removeField(data: FieldDrag) {
    data.row.splice(data.fieldIndex, 1);
  }

  private removeEmptyRows() {
    const toRemove = this.fields.filter(row => row.length === 0);
    for (const row of toRemove) {
      const index = this.fields.indexOf(row);
      this.fields.splice(index, 1);
    }
  }

  private finishDragAndDrop() {
    this.draggedField = undefined;
    this.dragoverRowIndex = undefined;
    this.dragoverFieldIndex = undefined;
  }

  private updatePositions(data: FieldDrag, targetRowIndex: number) {
    const sourceRow = data.row;
    const targetRow = this.fields[targetRowIndex];

    this.removeEmptyRows();

    for (let rowIndex = 0; rowIndex < this.fields.length; rowIndex++) {
      const row = this.fields[rowIndex];
      const columnWidth = Math.floor(12 / row.length);

      const resetColumnsWidth = row === targetRow || row === sourceRow;

      for (let fieldIndex = 0; fieldIndex < row.length; fieldIndex++) {
        const field = row[fieldIndex];

        field.positioning.row = rowIndex;
        field.positioning.position = fieldIndex;

        field.positioning.columns = resetColumnsWidth
          ? columnWidth
          : field.positioning.columns;
      }
    }
  }

  shouldHideRowBeforeDropzone(rowIndex: number) {
    if (this.draggedField === undefined || this.dragoverRowIndex === undefined) {
      return true;
    }
    if (this.dragoverRowIndex !== rowIndex) {
      return true;
    }
    if (this.draggedFieldRowIndex === rowIndex) {
      const row = this.fields[rowIndex];
      return row.length <= 1;
    }
    if (this.draggedFieldRowIndex === rowIndex - 1) {
      return true;
    }
    return false;
  }

  shouldHideRowAfterDropzone(rowIndex: number) {
    if (this.draggedField === undefined || this.dragoverRowIndex === undefined) {
      return true;
    }
    if (this.dragoverRowIndex !== rowIndex) {
      return true;
    }
    if (this.draggedFieldRowIndex === rowIndex) {
      const row = this.fields[rowIndex];
      return row.length <= 1;
    }
    if (this.draggedFieldRowIndex === rowIndex + 1) {
      return true;
    }
    return false;
  }

  shouldHideFieldBeforeDropzone(rowIndex: number, fieldIndex: number) {
    if (this.draggedField === undefined || this.dragoverRowIndex === undefined) {
      return true;
    }
    if (this.dragoverRowIndex !== rowIndex) {
      return true;
    }
    if (this.dragoverFieldIndex !== fieldIndex || this.dragoverFieldIndex === fieldIndex - 1) {
      return true;
    }
    if (this.draggedFieldRowIndex === rowIndex && this.draggedFieldIndex === fieldIndex) {
      return true;
    }
    if (this.draggedFieldRowIndex === rowIndex && this.draggedFieldIndex === fieldIndex - 1) {
      return true;
    }
    return false;
  }

  shouldHideFieldAfterDropzone(rowIndex: number, fieldIndex: number) {
    if (this.draggedField === undefined || this.dragoverRowIndex === undefined) {
      return true;
    }
    if (this.dragoverRowIndex !== rowIndex) {
      return true;
    }
    if (this.dragoverFieldIndex !== fieldIndex || this.dragoverFieldIndex === fieldIndex + 1) {
      return true;
    }
    if (this.draggedFieldRowIndex === rowIndex && this.draggedFieldIndex === fieldIndex) {
      return true;
    }
    if (this.draggedFieldRowIndex === rowIndex && this.draggedFieldIndex === fieldIndex + 1) {
      return true;
    }
    return false;
  }

  gripFieldHorizontally($event: DragEvent, rowWidthPx: number, row: DocumentTypeFieldConfig[], field: DocumentTypeFieldConfig, rowIndex: number) {
    if (row.length === 1) {
      return;
    }

    const currentIndex = row.indexOf(field);
    const next = row[currentIndex + 1];

    if (this.gripStart === undefined) {
      this.gripStart = {
        clientX: $event.clientX,
        leftColumns: field.positioning.columns,
        rightColumns: next.positioning.columns,
        otherColumns: row
          .filter(x => x !== field && x !== next)
          .reduce((sum, x) => sum + x.positioning.columns, 0)
      };
    }

    this.resizingRowIndex = rowIndex;

    const offsetX = this.gripStart.clientX - $event.clientX;

    const step = rowWidthPx / 12;
    const columnsOffset = Math.round(offsetX / step);

    if (Math.abs(this.gripColumnsOffset - columnsOffset) > 1) {
      // prevent weird flickering on each drag start
      return;
    }

    this.gripColumnsOffset = columnsOffset;

    const max = 12 - this.gripStart.otherColumns - 1;
    field.positioning.columns = clamp(this.gripStart.leftColumns - columnsOffset, 1, max);
    next.positioning.columns = clamp(this.gripStart.rightColumns + columnsOffset, 1, max);

    this.cdr.detectChanges();
  }

  finishGrip() {
    this.gripStart = undefined;
    this.gripColumnsOffset = 0;
    this.grippedRowColumns = undefined;
    this.resizingRowIndex = undefined;
    this.finishDragAndDrop();
    this.cdr.detectChanges();
  }

  toggleFieldDropdown(field: DocumentTypeFieldConfig) {
    if (this.toggledFieldId === undefined) {
      this.toggledFieldId = field.id;
      setTimeout(() => {
        this.resetFieldListener = this.resetToggledField.bind(this);
        window.addEventListener('click', this.resetFieldListener);
      });
    } else if (this.toggledFieldId !== field.id) {
      this.toggledFieldId = field.id;
      this.toggledFieldSwitched = true;
    } else {
      this.resetToggledField();
    }

    this.documentTypeConfiguratorPreviewService.setDocTypeFieldPreview(field.coordinates, field.page_number);

    this.cdr.detectChanges();
  }

  resetToggledField() {
    if (this.toggledFieldSwitched) {
      this.toggledFieldSwitched = false;
      return;
    }
    this.toggledFieldId = undefined;
    this.cdr.detectChanges();
    this.unsubscribeResetFieldListener();
  }

  openAddRemovedFieldsetFields(config: DocumentTypeSectionConfig) {
    this.modalService.customized(
      {
        initialState: {
          class: 'modal-xl',
          data: {
            document_type_fieldset_id: config.document_type_fieldset_id || this.formState.model.document_type_fieldset_id,
            fields: config.fields
          },
          tenant_id: this.tenant_id
        },
        ignoreBackdropClick: false,
        backdrop: true
      },
      AddRemovedFieldsetFieldsModalComponent
    ).subscribe((documentTypeRelations: DocumentTypeRelation[]) => {
      this.addRemovedFieldsetFieldRelations(documentTypeRelations, config.document_type_fieldset_id);
    });
  }

  private addRemovedFieldsetFieldRelations(documentTypeRelations: DocumentTypeRelation[], document_type_fieldset_id: number) {
    documentTypeRelations.forEach(relation => {
      const fieldConfig = {
        coordinates: validCoordinates(relation.document_type_field.coordinates) ? relation.document_type_field.coordinates : undefined,
        page_number: relation.document_type_field.page_number,
        document_type_fieldset_id,
        document_type_relation_id: undefined, // new relation
        id: relation.document_type_field.id,
        identifier: relation.document_type_field.identifier,
        title: relation.document_type_field.title,
        not_for_validation: relation.document_type_field.not_for_validation,
        optional: relation.document_type_field.optional,
        confidence_lower_threshold: relation.document_type_field.confidence_lower_threshold,
        confidence_upper_threshold: relation.document_type_field.confidence_upper_threshold,
        confidence_threshold: relation.document_type_field.confidence_threshold,
        recognition_confidence_threshold: relation.document_type_field.recognition_confidence_threshold,
        recognition_threshold: relation.document_type_field.recognition_threshold,
        not_for_training: relation.document_type_field.not_for_training,
        validation_help: relation.document_type_field.validation_help,
        output_data_type: relation.document_type_field.output_data_type,
        status: relation.document_type_field.status,
        positioning: this.determineNewFieldPositioning(),
        use_standard: true,
        extractionType: {
          title: 'text-extraction-type-title',
          label: 'text-extraction-type-label',
          description: 'text-extraction-type-description',
          version: 'text-extraction-type-version',
          target: {
            identifier: 'test-target'
          }
        }
      };

      this.config.fields.push(fieldConfig);
    });

    this.saveConfiguration();
  }

  openApplyDocumentTypeFieldsetModal() {
    this.modalService.customized(
      {
        initialState: {
          class: 'modal-xl fullwidth',
          action_alternative: () => this.addNewDocumentTypeFieldset(),
          data: {
            documentType: this.formState.documentType
          },
          tenant_id: this.tenant_id
        },
        ignoreBackdropClick: false,
        backdrop: true
      },
      AddFieldsetModalComponent
    ).subscribe((data: { fieldset: DocumentTypeFieldset; relations: DocumentTypeRelation[]; }) => {
      this.applyFieldset(data.fieldset, data.relations);
      this.saveConfiguration();
    });
  }

  applyFieldset(fieldset: DocumentTypeFieldset, fieldsetRelations: DocumentTypeRelation[]) {
    this.config.document_type_fieldset_id = Number(fieldset.id);
    this.config.fields.length = 0;
    for (const relation of fieldsetRelations) {
      this.config.fields.push({
        coordinates: validCoordinates(relation.document_type_field.coordinates) ? relation.document_type_field.coordinates : undefined,
        page_number: relation.document_type_field.page_number,
        document_type_fieldset_id: Number(fieldset.id),
        document_type_relation_id: undefined, // new relation
        id: relation.document_type_field.id,
        identifier: relation.document_type_field.identifier,
        title: relation.document_type_field.title,
        not_for_validation: relation.document_type_field.not_for_validation,
        optional: relation.document_type_field.optional,
        confidence_lower_threshold: relation.document_type_field.confidence_lower_threshold,
        confidence_upper_threshold: relation.document_type_field.confidence_upper_threshold,
        confidence_threshold: relation.document_type_field.confidence_threshold,
        recognition_confidence_threshold: relation.document_type_field.recognition_confidence_threshold,
        recognition_threshold: relation.document_type_field.recognition_threshold,
        not_for_training: relation.document_type_field.not_for_training,
        validation_help: relation.document_type_field.validation_help,
        output_data_type: relation.document_type_field.output_data_type,
        status: relation.document_type_field.status,
        positioning: { ...relation.positioning },
        use_standard: true,
        extractionType: {
          title: 'text-extraction-type-title',
          label: 'text-extraction-type-label',
          description: 'text-extraction-type-description',
          version: 'text-extraction-type-version',
          target: {
            identifier: 'test-target'
          }
        }
      });
    }
    this.fields = this.mapFieldsToRows(this.config.fields);
  }

  private deserializeFieldDragStartData(data: FieldDragStart): FieldDrag {
    // this is due to fact that dnd-draggable object gets serialized / deserialized on drag / drop
    // hence all the references are wiped out and we need to restore rich model

    const field = this.config.fields.find(x => x.id === data.fieldId);
    const row = this.fields.find(r => r.some(f => f === field));

    return {
      field, row,
      fieldIndex: data.fieldIndex,
      rowIndex: data.rowIndex,
      type: data.type
    };
  }

  private unsubscribeResetFieldListener() {
    if (this.resetFieldListener) {
      window.removeEventListener('click', this.resetFieldListener);
    }
  }

  goToFieldset(fieldset_id) {
    this.router.navigate(['../../../fieldsets/edit', fieldset_id], { relativeTo: this.route });
  }
}
