import { Component } from '@angular/core';
import { FieldArrayType, FormlyModule } from '@ngx-formly/core';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'document-type-fields-meta-list',
    templateUrl: './document-type-fields-meta-list.component.html',
    styleUrls: ['./document-type-fields-meta-list.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, FormlyModule]
})
export class DocumentTypeFieldsMetaListComponent extends FieldArrayType {}
