import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { CollapseModule } from 'ngx-bootstrap/collapse';

@Component({
  selector: 'document-type-field-meta-body-wrapper',
  templateUrl: './document-type-field-meta-body-wrapper.component.html',
  styleUrls: ['./document-type-field-meta-body-wrapper.component.scss'],
  standalone: true,
  imports: [CollapseModule]
})
export class DocumentTypeFieldMetaBodyWrapperComponent extends FieldWrapper {
  @ViewChild('fieldComponent', { read: ViewContainerRef, static: true }) fieldComponent: ViewContainerRef;
}
