import { Component, DestroyRef, Inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgFor, NgIf, NgClass } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { ActivatedRoute } from '@angular/router';
import { FieldArrayType, FormlyModule } from '@ngx-formly/core';
import { DocumentTypeSectionConfig } from '../document-type-config';
import { ModalService } from 'shared/components/modals/modal.service';
import { DocumentTypeHelperService } from '../../../services/document-type-helper.service';

@Component({
  selector: 'document-type-sections-configurator',
  templateUrl: './sections-configurator.component.html',
  styleUrls: ['./sections-configurator.component.scss'],
  standalone: true,
  imports: [NgFor, NgIf, ReactiveFormsModule, FormsModule, FaIconComponent, TooltipModule, FormlyModule, NgClass]
})
export class DocumentTypeSectionsConfiguratorComponent extends FieldArrayType implements OnInit {

  private tenant_id: number;

  constructor(
    private route: ActivatedRoute,
    private modalService: ModalService,
    public documentTypeHelperService: DocumentTypeHelperService,
    @Inject(DestroyRef) private destroyRef: DestroyRef
  ) {
    super();
  }

  ngOnInit() {
    this.route.paramMap.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(params => {
      this.tenant_id = params.get('tenant_id') ? Number(params.get('tenant_id')) : undefined;
    });
  }

  removeSection(i) {
    this.modalService
      .confirmation({
        initialState: {
          description: $localize `:@@shared_pdc.components.document_type_configurator.document_type_section_delete_description:Do you want to delete this Document Type section?`,
          title: $localize `:@@shared_pdc.components.document_type_configurator.document_type_section_delete_title:Delete Document Type section`
        }
      })
      .subscribe(() => {
        const section = this.model[i];
        this.removeDocumentTypeSection(i);
        if (section.id) {
          this.props.hooks.saveConfiguration();
        }
      });
  }

  private removeDocumentTypeSection(i) {
    if (this.props.onRemoveSection) {
      this.props.onRemoveSection(this.model[i], i);
    }
    this.remove(i);
  }

  removeAllSections() {
    for (let i = this.model.length - 1; i >= 0; i--) {
      super.remove(i);
    }
  }

  moveUp(position) {
    const new_position = position - 1;
    const section = this.model[position];

    if (new_position < 0) {
      this.applyModel([...this.model.slice(1, this.model.length), section]);
      return;
    }

    this.replace(position, new_position);
  }

  moveDown(position) {
    const new_position = position + 1;
    const section = this.model[position];

    if (new_position > this.model.length - 1) {
      this.applyModel([section, ...this.model.slice(0, this.model.length - 1)]);
      return;
    }

    this.replace(position, new_position);
  }

  add(i?: number, initialModel?: any): void {
    initialModel = initialModel || ({
      title: 'New section',
      fields: [],
      allow_edit: true,
      position: this.model.length
    } as Partial<DocumentTypeSectionConfig>);
    super.add(i, initialModel);
    if (this.props.hooks && this.props.hooks.itemAdded) {
      this.props.hooks.itemAdded(initialModel);
    }
  }

  remove(i: number): void {
    super.remove(i);
  }

  private replace(oldPosition: number, newPosition: number) {
    const reordered = [...this.model];
    const temp = reordered[newPosition];
    reordered[newPosition] = reordered[oldPosition];
    reordered[oldPosition] = temp;
    this.applyModel(reordered);
  }

  private applyModel(newModel: any[]): void {
    while (this.model.length !== 0) {
      this.remove(0);
    }
    for (let i = 0; i < newModel.length; i++) {
      const section = newModel[i];
      section.position = i;
      this.add(undefined, section);
    }
  }
}
