import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-session-callback',
  templateUrl: './callback.component.html',
  standalone: true
})
export class SessionCallbackComponent {
  constructor(private router: Router, private authService: AuthService) {
    this.authService.handleRedirect().subscribe(redirect_to => {
      if (Array.isArray(redirect_to)) {
        this.router.navigate(redirect_to);
      } else {
        this.router.navigateByUrl(redirect_to);
      }
    });
  }
}
