<!-- Header -->
<div class="modal-header">

  <!-- Title -->
  <h6 class="modal-title" i18n="@@shared_pdc.components.add_removed_fieldset_fields_modal.title">Re-Apply removed field</h6>
  <!-- Title end -->

  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<!-- Header end -->

<!-- Content -->
<div class="modal-body scroll-container">

  <div class="row">

    <!-- Left Column -->
    <!-- <left-column *ngIf="filterColumnConfig" class="col-auto left-column"
                 [identifiers]="['filter']"
                 [icons]="['filter']"
                 #lc>
      <filter-column *ngIf="lc.show === 'filter' || !lc.show"
                     [listFilter]=listFilter
                     [filterConfig]="filterColumnConfig">
      </filter-column>
    </left-column> -->
    <!-- Left Column end -->

    <!-- Main Column -->
    <div class="col scroll-horizontal">

      <!-- List & Filters -->
      <div *resultSwitch="documentTypeRelations?.getModels().length; listFilter: listFilter; templatePart: 'result'">
          <!-- <div class="row mb-3 font-size-base">
            <div class="col-8">
              <filter-tags [filterConfig]="filterColumnConfig"></filter-tags>
            </div>
            <div class="col-4">
              <div class="float-end">
                <filter-sorting [listFilter]="listFilter"
                                [filterConfig]="filterColumnConfig"
                ></filter-sorting>
              </div>
            </div>
          </div> -->

          <!-- Result -->
          <div *ngIf="documentTypeRelations?.getModels().length > 0; else no_result">
            <div class="table-responsive">
              <table class="table">
                <thead class="table-dark">
                  <tr>
                    <th i18n="@@common.name">Name</th>
                    <th i18n="@@common.extractor_attribute">Extractor Attribute</th>
                    <th i18n="@@common.description">Description</th>
                    <th i18n="@@common.locales">Locales</th>
                    <th i18n="@@common.issuer">Issuer</th>
                    <th i18n="@@common.type">Type</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <ng-template ngFor let-documentTypeRelation [ngForOf]="documentTypeRelations?.getModels()" let-index="index">
                    <tr>
                      <td><strong tooltip="{{ '#' + documentTypeRelation.document_type_field?.id + ': ' + documentTypeRelation.document_type_field?.identifier }}">{{ documentTypeRelation.document_type_field?.title }}</strong></td>
                      <td>{{ documentTypeRelation.document_type_field?.extractor_attribute }}</td>
                      <td>
                        <fa-icon [icon]="['fas', 'exclamation-circle']"
                                 [fixedWidth]="true"
                                 *ngIf="documentTypeRelation.document_type_field?.description"
                                 tooltip="{{ documentTypeRelation.document_type_field?.description }}">
                        </fa-icon>
                      </td>
                      <td><span *ngFor="let locale of documentTypeRelation.document_type_field?.locales" class="badge rounded-pill text-bg-light me-1">{{ locale.code }}</span></td>
                      <td>{{ documentTypeRelation.document_type_field?.tenant_id }}</td>
                      <td>{{ type[documentTypeRelation.document_type_field?.getType(tenant_id)] }}</td>
                      <td>
                        <div class="form-check">
                          <input type="checkbox"
                                 [id]="'add-field-' + index"
                                 class="form-check-input"
                                 (click)="handleFieldSelection($event, documentTypeRelation)"
                                 [checked]="excludedFieldIds.includes(documentTypeRelation.document_type_field?.id) || selectedFieldIds.includes(documentTypeRelation.document_type_field?.id)"
                                 [disabled]="excludedFieldIds.includes(documentTypeRelation.document_type_field?.id)">
                          <label class="form-check-label" [for]="'add-field-' + index"></label>
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </tbody>
              </table>
            </div>
            <!-- <div class="row">
              <div class="col-auto">
                <pagination [boundaryLinks]="true"
                            [totalItems]="documentTypeRelations?.getMeta().meta.stats.total.count"
                            [itemsPerPage]="20"
                            [maxSize]="10"
                            [ngModel]="listFilter.page.number"
                            (pageChanged)="filterComponent.setPageValue($event)"
                            previousText="&lsaquo;"
                            nextText="&rsaquo;"
                            firstText="&laquo;"
                            lastText="&raquo;"
                ></pagination>
              </div>
              <div class="col">
                <div *ngIf="documentTypeRelations?.getMeta().meta.stats.total.count > 0"
                     appListResultCounter
                     [pageNumber]="listFilter.page.number"
                     [pageSize]="20"
                     [totalCount]="documentTypeRelations?.getMeta().meta.stats.total.count"
                     modelName="{{ 'model_title.document_type_field' { count: documentTypeRelations?.getMeta().meta.stats.total.count } }}"
                ></div>
              </div>
            </div> -->
          </div>
          <!-- Result end -->

          <!-- No Result -->
          <ng-template #no_result>
            <empty-result-description model="document_type_field"></empty-result-description>
          </ng-template>
          <!-- No Result end -->

      </div>
      <!-- List & Filters end -->

    </div>
    <!-- Main Column end -->

  </div>
</div>
<!-- Content end -->

<!-- Footer -->
<div class="modal-footer justify-content-between">
  <div class="text-start">
  </div>
  <div class="text-end">
    <button type="button"
            class="btn btn-xs btn-secondary me-1"
            (click)="close()"
     i18n="@@common.cancel">Cancel</button>
    <button type="button"
            class="btn btn-xs btn-success me-1"
            (click)="addSelectedFields()"
            [disabled]="selectedRelations.length === 0"
     i18n="@@shared_pdc.components.add_removed_fieldset_fields_modal.submit">Apply field</button>
  </div>
</div>
<!-- Footer end -->
