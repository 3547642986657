import { HttpInterceptorFn } from '@angular/common/http';

export const headerContentTypeInterceptor: HttpInterceptorFn = (req, next) => {
  const contentType = req.headers.get('Content-Type');

  if (contentType === null) {
    const clonedReq = req.clone({
      setHeaders: {
        'Content-Type': `application/vnd.api+json`
      }
    });

    return next(clonedReq);
  }

  return next(req);
};
