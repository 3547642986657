import { Component, DestroyRef, Inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AsyncPipe } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { isObservable, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { IMultiSelectOption, IMultiSelectSettings, NgxBootstrapMultiselectModule } from '@parashift/ngx-bootstrap-multiselect';
import { Option } from 'shared/components/filter-column/filters/selectbox/selectbox.component';
import { isNullOrUndefined } from 'shared/components/forms/types/pdc/utils/utils';
import { mapOptionsToMultiSelectboxOptions } from '@parashift/shared/utils';

@Component({
    selector: 'pdc-multi-selectbox',
    templateUrl: './multi-selectbox.component.html',
    styleUrls: ['./multi-selectbox.component.scss'],
    standalone: true,
    imports: [NgxBootstrapMultiselectModule, ReactiveFormsModule, FormsModule, AsyncPipe]
})
export class MultiSelectboxComponent extends FieldType<FieldTypeConfig> implements OnInit {

  options$: Observable<IMultiSelectOption[]>;

  settings: IMultiSelectSettings = {
    buttonClasses: 'form-control form-control-sm',
    dynamicTitleMaxItems: 1
  };

  get selectedOptions() { return this._selectedOptions; }
  set selectedOptions(options) {
    this._selectedOptions = options;
    this.formControl.setValue(options.length === 0 ? null : options.join(','));
  }
  private _selectedOptions: string[] = undefined; // array of options' ids

  constructor(@Inject(DestroyRef) private destroyRef: DestroyRef) {
    super();
  }

  ngOnInit() {
    this.initSelectedOptions();
    this.initMultiSelectboxSettings();

    const { options } = this.props;
    const options$ = isObservable(options) ? options as Observable<Option[]> : of(options);
    this.options$ = options$.pipe(
      takeUntilDestroyed(this.destroyRef),
      map(opts => mapOptionsToMultiSelectboxOptions(opts, x => x))
    );
  }

  private initSelectedOptions() {
    const value = this.model[this.key as string];
    if (isNullOrUndefined(value)) {
      return;
    }
    this.selectedOptions = ('' + value).split(',').filter(x => x !== '');
  }

  private initMultiSelectboxSettings() {
    this.settings.autoUnselect = this.props.single ? true : undefined;
    this.settings.selectionLimit = this.props.single ? 1 : undefined;
  }
}
