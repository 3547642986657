import { Component } from '@angular/core';
import { InputFieldType } from 'shared/components/forms/types/input-field-type';
import { FormlyModule } from '@ngx-formly/core';

@Component({
    selector: 'input-display',
    templateUrl: './input-display.component.html',
    standalone: true,
    imports: [FormlyModule]
})
export class InputDisplayComponent extends InputFieldType {
  get keyAsString() {
    return this.key as string;
  }
}
