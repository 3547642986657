import { Attribute, BelongsTo, Model } from './decorators';
import { BaseApiModel } from './base';
import { ExtractionTypeAdditionalExtractorType, ExtractionTypeType } from '@parashift/shared/types';
import { ExtractionType } from './extraction-type.model';

export interface ExtractionTypeAdditionalExtractorPlainModel {
  id: string;
  created_at: string;
  extraction_type_id: number;
  additional_extraction_type_id: number;
  step: number;
  updated_at: string;

  additional_extraction_type: ExtractionType['plainModel'] | Partial<ExtractionType['plainModel']>;
  extraction_type: ExtractionType['plainModel'] | Partial<ExtractionType['plainModel']>;
}

@Model({ type: ExtractionTypeAdditionalExtractorType })
export class ExtractionTypeAdditionalExtractor extends BaseApiModel {

  @Attribute()
  created_at: string;

  @Attribute()
  extraction_type_id: number;

  @Attribute()
  additional_extraction_type_id: number;

  @Attribute()
  step: number;

  @Attribute()
  updated_at: string;

  // Includes / Relations

  @BelongsTo({ class: ExtractionTypeType })
  additional_extraction_type: ExtractionType;

  @BelongsTo({ class: ExtractionTypeType })
  extraction_type: ExtractionType;
}
