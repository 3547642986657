import { Component, DestroyRef, Inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { TypeaheadMatch, TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { Observable, Observer } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

@Component({
    selector: 'tenant-typeahead',
    templateUrl: './tenant-typeahead.component.html',
    styleUrls: ['./tenant-typeahead.component.scss'],
    standalone: true,
    imports: [ReactiveFormsModule, FormlyModule, TypeaheadModule, FaIconComponent]
})
export class TenantTypeaheadComponent extends FieldType<FieldTypeConfig> implements OnInit {
  dataSource: Observable<any>;
  typeaheadOptionsLimit = 7;
  typeaheadSelectFirstItem = true;
  typeaheadIsFirstItemActive = true;

  get tenantTypeaheadFormControl() {
    return this.formControl as FormControl;
  }

  constructor(@Inject(DestroyRef) private destroyRef: DestroyRef) {
    super();
  }

  ngOnInit() {
    this.initModelValue();

    if (this.props.typeaheadOptionsLimit) {
      this.typeaheadOptionsLimit = this.props.typeaheadOptionsLimit;
    }

    if (this.props.typeaheadSelectFirstItem !== undefined) {
      this.typeaheadSelectFirstItem = this.props.typeaheadSelectFirstItem;
    }

    if (this.props.typeaheadIsFirstItemActive !== undefined) {
      this.typeaheadIsFirstItemActive = this.props.typeaheadIsFirstItemActive;
    }

    this.dataSource = new Observable((observer: Observer<any>) => {
      observer.next(this.formControl);
    }).pipe(
      takeUntilDestroyed(this.destroyRef),
      debounceTime(this.props.debounceTime || 400),
      distinctUntilChanged(),
      filter(term => term !== null),
      switchMap(this.props.search || this.showMissingConfiguration)
    );
  }

  initModelValue() {
    if (this.props.translateIdToOption && this.model[this.props.selectedModel] && this.props.shownOption) {
      const item = this.props.translateIdToOption(this.model[this.props.selectedModel]);

      if (item  && item instanceof Observable) {
        item.subscribe({
          next: model => {
            if (model && model[this.props.shownOption]) {
              this.formControl.setValue(
                model[this.props.shownOption],
                { emitEvent: this.props.inRepeatMode ? true : false}
              );
            }
          },
          error: () => this.model[this.props.selectedModel] = null
        });
      } else if (item && item[this.props.shownOption]) {
        this.formControl.setValue(
          item[this.props.shownOption],
          { emitEvent: this.props.inRepeatMode ? true : false}
        );
      } else {
        this.model[this.props.selectedModel] = null;
      }
    } else {
      this.model[this.props.selectedModel] = null;
    }
  }

  showMissingConfiguration() {
    return new Observable((observer: Observer<any[]>) => {
      observer.next(['Please configure a function search in props!']);
    });
  }

  onSelect(event: TypeaheadMatch): void {
    if (this.props.selectedModelAttribute === undefined) {
      this.model[this.props.selectedModel] = event.item;
    } else {
      this.model[this.props.selectedModel] = event.item[this.props.selectedModelAttribute];
    }

    if (this.props.onSelect) {
      this.props.onSelect(event, this.model, this.formControl);
    }
  }
}
