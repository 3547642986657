import { Component, OnInit, ElementRef, HostListener, ViewChild } from '@angular/core';
import { NgClass, NgIf } from '@angular/common';
import { FormControl, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { BsDatepickerConfig, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownDirective, BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { SessionService } from '@parashift/shared/services';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { format, isValid, parse } from 'date-fns';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { OffClickDirective } from './offclick.directive';

@Component({
  selector: 'datetimepicker-type',
  templateUrl: './datetimepicker.component.html',
  styleUrls: ['./datetimepicker.component.scss'],
  standalone: true,
  imports: [
    FaIconComponent,
    ReactiveFormsModule,
    FormlyModule,
    BsDropdownModule,
    OffClickDirective,
    NgClass,
    NgIf,
    BsDatepickerModule,
    TimepickerModule,
    FormsModule
  ]
})
export class DatetimepickerComponent extends FieldType<FieldTypeConfig> implements OnInit {
  @ViewChild('dropdown', { static: true }) dropdown: BsDropdownDirective;

  appendTo = undefined;
  showDate = true;
  showTime = true;
  showWeeks = true;
  showMeridian = false;
  showSeconds = false;
  datepickerMode = 'day';
  dateDisabled: any[] = [];
  minuteStep = 5;
  hourStep = 1;
  secondsStep = 1;

  bsConfig: Partial<BsDatepickerConfig> = { containerClass: 'theme-dark-blue' };
  localValue: Date = null;
  format: string;
  isOpening = false;
  isDropUp = false;
  iconToggle = false;

  get datepickerFormControl() {
    return this.formControl as FormControl;
  }

  constructor(
    private elementRef: ElementRef,
    private localeService: BsLocaleService,
    private sessionService: SessionService
  ) {
    super();
    this.localeService.use(this.sessionService.user.language);
  }

  ngOnInit() {
    this.appendTo = this.props.appendTo || undefined;
    this.showDate = this.props.showDate === false ? false : true;
    this.showTime = this.props.showTime === false ? false : true;
    this.showWeeks = this.props.showWeeks === false ? false : true;
    this.showMeridian = this.props.showMeridian || false;
    this.showSeconds = this.props.showSeconds || false;
    this.datepickerMode = 'day';
    this.dateDisabled = this.props.dateDisabled || [];
    this.minuteStep = this.props.minuteStep || 5;
    this.hourStep = this.props.hourStep || 1;
    this.secondsStep = this.props.secondStep || 1;

    this.props.minDate = this.props.minDate || null;
    this.props.maxDate = this.props.maxDate || null;

    this.initFormat();
    this.initValue();
  }

  private initFormat() {
    const format = [];
    if (this.showDate) {
      format.push('dd.MM.yyyy');
    }
    if (this.showTime) {
      format.push('HH:mm');
    }

    this.format = format.join(' ');
  }

  private initValue() {
    if (this.formControl.value && isValid(parse(this.formControl.value, this.format, new Date()))) {
      this.localValue = parse(this.formControl.value, this.format, new Date());
    }
  }

  onFocus($event: any) {
    $event.stopPropagation();
    $event.preventDefault();
    this.handleDropdown(true);
  }

  @HostListener('window:scroll', [])
  @HostListener('window:resize', [])
  public onWindowScroll() {
    const nativeEl: HTMLElement = this.elementRef.nativeElement;
    const clientRect: DOMRect = nativeEl.getBoundingClientRect();
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    const offsetTop = clientRect.top + window.scrollY;
    const height = clientRect.height;
    const dropdownEl: HTMLElement = nativeEl.children.item(0) as HTMLElement;
    const menuEl: HTMLElement = dropdownEl.children.length > 0 ? dropdownEl.children.item(0) as HTMLElement : null;
    let menuHeight = this.showDate && this.showTime ? 461 : this.showDate ? 300 : 102;

    if (menuEl != null) {
      const display = menuEl.style.display;
      menuEl.style.display = 'block';

      menuHeight = menuEl.getBoundingClientRect().height;
      menuEl.style.display = display;
    }

    if ((offsetTop - menuHeight) <= 0) {
      this.isDropUp = false;
    } else {
      this.isDropUp = ((offsetTop + height + menuHeight) > (scrollTop + document.documentElement.clientHeight));
    }
  }

  toggleDropdown() {
    if (this.props.disabled) {
      return;
    }

    if (!this.iconToggle) {
      this.dropdown.show();
    } else {
      this.iconToggle = false;
    }
  }

  private handleDropdown(open: boolean) {
    if (open) {
      this.dropdown.show();
    } else {
      this.dropdown.hide();
    }
  }

  onOpenChange(opened: boolean) {
    if (opened === true) {
      this.isOpening = true;
      this.onWindowScroll();
      this.dropdown.show();
      this.iconToggle = true;

      setTimeout(() => this.isOpening = false, 250);
    } else {
      this.handleDropdown(false);
      this.iconToggle = true;
      setTimeout(() => this.iconToggle = false, 500);
    }
  }

  onNow() {
    this.localValue = new Date();
    this.setDate();
    this.onPickerChange(this.localValue, 'timepicker');
  }

  onClear() {
    this.formControl.setValue(null);
    this.localValue = null;
  }

  onClose() {
    this.handleDropdown(false);
  }

  onOffClick() {
    this.handleDropdown(false);
  }

  onPickerChange(date: Date, picker: string) {
    if (this.isOpening === true) {
      return;
    }

    if (picker === 'datepicker' && date) {
      this.setDate();
    }

    // if (picker === 'datepicker' && this.formControl.value != null && this.localValue) {
    //   const currentDate = parse(this.formControl.value, 'dd.MM.yyyy HH:mm', new Date());
    //   const hours = currentDate.getHours();
    //   const minutes = currentDate.getMinutes();
    //   const seconds = currentDate.getSeconds();
    //   const milliseconds = currentDate.getMilliseconds();
    //   this.localValue.setHours(hours, minutes, seconds, milliseconds);
    // }

    if (date) {
      const displayDate = format(this.localValue, this.format);
      this.formControl.setValue(displayDate);
    }

    if (this.showDate === true && this.showTime === false) {
      this.dropdown.hide();
    }
  }

  private setDate() {
    const hours = this.hourStep > 1 ? (Math.round(this.localValue.getHours() / this.hourStep) * this.hourStep) % 60 : this.localValue.getHours();
    const minutes = this.minuteStep > 1 ? (Math.round(this.localValue.getMinutes() / this.minuteStep) * this.minuteStep) % 60 : this.localValue.getMinutes();
    const seconds = this.showSeconds ? this.localValue.getSeconds() : 0;
    const milliseconds = 0;
    this.localValue.setHours(hours, minutes, seconds, milliseconds);
  }
}
