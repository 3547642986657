import { ValidationErrors } from '@angular/forms';
import { dateValidationError } from 'shared/components/forms/validators/date/date-validation-error';
import { dateValidationSuccess } from 'shared/components/forms/validators/date/date-validation-success';

interface DateValidatorInput {
  readonly value: string;
}

export function DateValidator({ value }: DateValidatorInput): ValidationErrors {
  if (!value) {
    return dateValidationSuccess();
  }
  const [day, month, year] = value
    .split('.')
    .map(x => x.replace(/_/g, ''))
    .map(x => x === '' ? undefined : Number(x));
  if (day === undefined && month === undefined && year === undefined) {
    // empty date
    return dateValidationSuccess();
  }
  if (!day || !month || !year) {
    return dateValidationError();
  }
  const date = new Date(year, month - 1, day, 0, 0, 0, 0);
  return day === date.getDate() && month === date.getMonth() + 1 && year === date.getFullYear() && date.getFullYear() >= 1900
    ? dateValidationSuccess()
    : dateValidationError();
}
