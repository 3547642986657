<div class="input-group input-group-sm datetimepicker-component">
  <span class="input-group-text pointer" (click)="toggleDropdown()"><fa-icon [icon]="['fas', 'calendar-alt']"></fa-icon></span>

  <input class="form-control"
    type="text"
    [formControl]="datepickerFormControl"
    [formlyAttributes]="field"
    [class.is-invalid]="showError"
    (focus)="onFocus($event)"
    (mouseup)="onFocus($event)" readonly>

  <div
    [container]="appendTo"
    (isOpenChange)="onOpenChange($event)"
    [dropup]="isDropUp"
    dropdown
    #dropdown="bs-dropdown"
    [autoClose]="false"
    (offClick)="onOffClick()"
    class="datetimepicker-dropdown">
    <ul class="datetime-popup dropdown-menu" role="menu" [ngClass]="{ 'show': dropdown.isOpen === true, 'body-container': appendTo === 'body', 'dropdown': !isDropUp, 'dropup': isDropUp }" *dropdownMenu>
      <li class="my-0 mx-2">
        <bs-datepicker-inline
          *ngIf="showDate"
          class="mb-2"
          [(bsValue)]="localValue"
          (bsValueChange)="onPickerChange($event, 'datepicker')"
          [bsConfig]="bsConfig"
          [minDate]="props.minDate"
          [maxDate]="props.maxDate"
        ></bs-datepicker-inline>
        <timepicker
          *ngIf="showTime"
          [(ngModel)]="localValue"
          [min]="props.minDate"
          [max]="props.maxDate"
          [hourStep]="hourStep"
          [minuteStep]="minuteStep"
          [secondsStep]="secondsStep"
          [showSeconds]="showSeconds"
          [showMeridian]="showMeridian"
          [showSpinners]="false"
          (ngModelChange)="onPickerChange($event, 'timepicker')"
        ></timepicker>
      </li>
      <li class="mx-2 mb-2 pt-2">
        <span class="btn-group float-start" *ngIf="showDate || showTime">
            <button type="button"
                    class="btn btn-secondary btn-sm"
                    (click)="onNow()" i18n="@@shared.components.forms.datetimepicker.now">Now</button>
            <button type="button"
                    class="btn btn-secondary btn-sm"
                    (click)="onClear()" i18n="@@shared.components.forms.datetimepicker.clear">Clear</button>
        </span>
        <span class="btn-group float-end">
            <button type="button"
                    class="btn btn-secondary btn-sm"
                    (click)="onClose()" i18n="@@common.close">Close</button>
        </span>
      </li>
    </ul>
  </div>
</div>
