<div class="form-group" [class.has-error]="showError">
  <label *ngIf="props.label && props.hideLabel !== true" [attr.for]="id">
    {{ props.label }}
    <span *ngIf="props.required && props.hideRequiredMarker !== true">*</span>
  </label>
  <ng-template #fieldComponent></ng-template>
  <div *ngIf="showError" class="invalid-feedback" [style.display]="'block'">
    <formly-validation-message [style.display]="'none'" #validationRef [field]="field"></formly-validation-message>
    <!-- FD CHECK: <ng-container *ngIf="validationRef && validationRef.errorMessage"></ng-container> -->
    <ng-container *ngIf="validationRef && validationRef.errorMessage">
      {{ validationRef.errorMessage }}
    </ng-container>
  </div>
  <small *ngIf="props.description" class="form-text text-muted">{{ props.description }}</small>
</div>
