<div class="form-check">
  <input class="form-check-input" type="checkbox"
    [class.is-invalid]="showError"
    [indeterminate]="props.indeterminate && formControl.value === null"
    [formControl]="checkboxFormControl"
    [formlyAttributes]="field">
  <label class="form-check-label" [for]="id">
    {{ props.label }}
    <span *ngIf="props.required && props.hideRequiredMarker !== true">*</span>
  </label>
</div>
