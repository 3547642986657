import { Injectable } from '@angular/core';
import { ActiveStorageAttachment, DocumentTask, ExtractionTypeAdditionalExtractor, Invite, Role, TargetVersion, Topic, WebhookEventLog } from '@parashift/shared/models';

// stop too aggresive tree shaking from removing model classes which are included indirectly only (e.g. DocumentTask).
// see more: https://gitlab.com/parashift/frontend/pdc/frontend-pdc-app/-/merge_requests/39
@Injectable({
  providedIn: 'root'
})
export class TreeShakingBusterService {
  init() {
    let x: any;

    /* eslint-disable @typescript-eslint/no-unused-vars */
    x = new ActiveStorageAttachment({});
    x = new DocumentTask({});
    x = new ExtractionTypeAdditionalExtractor({});
    x = new Invite({});
    x = new Role({});
    x = new TargetVersion({});
    x = new Topic({});
    x = new WebhookEventLog({});
    /* eslint-enable @typescript-eslint/no-unused-vars */
  }
}
