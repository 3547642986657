<input class="form-control"
  type="text"
  [formControl]="typeaheadFormControl"
  [formlyAttributes]="field"
  [class.is-invalid]="showError"
  [typeahead]="dataSource"
  [typeaheadOptionsLimit]="typeaheadOptionsLimit"
  [typeaheadMinLength]="0"
  [typeaheadLatinize]="true"
  [typeaheadSelectFirstItem]="typeaheadSelectFirstItem"
  [typeaheadIsFirstItemActive]="typeaheadIsFirstItemActive"
  (typeaheadOnSelect)="onSelect($event)"
  typeaheadOptionField="searchable"
  container="body"
>
