import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, UrlSegment } from '@angular/router';
import { Observable, of } from 'rxjs';
import { delay, switchMap } from 'rxjs/operators';
import { RefreshTokenService, CurrentTenantRegister, NotificationService } from '@parashift/shared/services';

@Injectable({
  providedIn: 'root'
})
export class PdcGuardService {

  private currentTenantId: string;

  constructor(
    private router: Router,
    private refreshTokenService: RefreshTokenService,
    private currentTenantRegister: CurrentTenantRegister,
    private notificationService: NotificationService
  ) {}

  canMatch(segments: UrlSegment[]): Observable<boolean> {
    const tenantId = this.getCurrentTenantId(segments);
    if (tenantId !== this.currentTenantId) {
      this.currentTenantId = tenantId;
      return this.refreshTokenService.get(this.currentTenantId).pipe(
        delay(200),
        switchMap(() => this.currentTenantRegister.getTenant(this.currentTenantId)),
        switchMap(() => this.checkRouteAndTenantPermissions())
      );
    } else {
      return of(true);
    }
  }

  canDeactivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    if (this.currentTenantId === this.getCurrentTenantId(route.url)) {
      this.currentTenantId = undefined;
      this.currentTenantRegister.setTenant(undefined);
    }

    return of(true);
  }

  private getCurrentTenantId(segments: UrlSegment[]) {
    return segments?.[1]?.path ?? undefined;
  }

  private checkRouteAndTenantPermissions() {
    const allowed = this.currentTenantRegister.isCurrentUserAllowed();

    if (!allowed) {
      this.notificationService.error({
        title: $localize `:@@pdc_guard.forbidden_title:Forbidden`,
        description: $localize `:@@pdc_guard.forbidden_description:You dont have permissions to access these resources`
      });
      this.router.navigate(['/missing-configuration', { reason: 'pdc' }]);
      return of(false);
    }

    return of(true);
  }
}
