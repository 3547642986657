<ng-container *ngFor="let field of field.fieldGroup; let i = index">
  <div *ngIf="i >= renderingStartIndex"
       [ngClass]="{'border-bottom': model && model.length > 1 && i < (model.length - 1) && !field.hide}">
    <div *ngIf="!field.hide" class="repeat-section">
      <div class="repeat-section-remove">
        <button type="button"
                class="close"
                aria-label="Remove"
                (click)="removeSection(i)"
                [disabled]="form.disabled"
                [hidden]="props.fieldsOnly === true"
                *ngIf="!props.readonly">
          <fa-icon [icon]="['fas', 'times-circle']"
                   transform="shrink-3"
                   [fixedWidth]="true"
                   aria-hidden="true"
                   i18n-tooltip="@@common.remove" tooltip="Remove"
                   container="body"
                   containerClass="danger">
          </fa-icon>
        </button>
        <button type="button"
                class="close up"
                aria-label="Move up"
                (click)="moveUp(i)"
                [disabled]="form.disabled"
                *ngIf="props.showOrdering && model.length > 1 && !props.readonly">
          <fa-icon [icon]="['fas', 'chevron-circle-up']"
                   transform="shrink-3"
                   [fixedWidth]="true"
                   aria-hidden="true"
                   i18n-tooltip="@@common.move_up" tooltip="Move up"
                   container="body">
          </fa-icon>
        </button>
        <button type="button"
                class="close down"
                aria-label="Move down"
                (click)="moveDown(i)"
                [disabled]="form.disabled"
                *ngIf="props.showOrdering && model.length > 1 && !props.readonly">
          <fa-icon [icon]="['fas', 'chevron-circle-down']"
                   transform="shrink-3"
                   [fixedWidth]="true"
                   aria-hidden="true"
                   i18n-tooltip="@@common.move_down" tooltip="Move down"
                   container="body">
          </fa-icon>
        </button>
      </div>
      <formly-field [field]="field"></formly-field>
    </div>
  </div>
</ng-container>

<div class="d-flex justify-content-between">
  <div class="repeat-section-button" *ngIf="!props.readonly" [hidden]="((props.maxItems || props.maxItems === 0) && props.maxItems <= model.length) || props.fieldsOnly === true">
    <button type="button" class="btn btn-dark" [ngClass]="props.btnClass ? props.btnClass : 'btn-sm'" (click)="add()" [disabled]="form.disabled || field.props.disabled">{{ props.labelAdd }}</button>
  </div>
  <div *ngIf="model && model.length > 0 && props.showRemoveAllSectionsButton && !props.readonly">
    <button type="button" class="btn btn-danger btn-sm" (click)="confirmRemovingAllSections()" [disabled]="form.disabled">{{ props.labelRemoveAllSections }}</button>
  </div>
</div>
