import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable()
export class ApplicationInitService {

  constructor(private authService: AuthService) {}

  handleAuthentication() {
    return this.authService.handleAuthentication();
  }
}
