import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgIf, NgStyle, NgClass } from '@angular/common';

@Component({
  selector: 'addons-wrapper',
  templateUrl: './addons-wrapper.component.html',
  standalone: true,
  imports: [NgIf, NgStyle, NgClass, FaIconComponent]
})
export class AddonsWrapperComponent extends FieldWrapper {
  @ViewChild('fieldComponent', { read: ViewContainerRef, static: true }) fieldComponent: ViewContainerRef;

  addonRightClick($event: any) {
    if (this.props.addonRight.onClick) {
      this.props.addonRight.onClick(this.props, this, $event);
    }
  }

  addonLeftClick($event: any) {
    if (this.props.addonLeft.onClick) {
      this.props.addonLeft.onClick(this.props, this, $event);
    }
  }
}
