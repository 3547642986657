import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper, FormlyModule } from '@ngx-formly/core';
import { NgIf } from '@angular/common';

@Component({
  selector: 'multicheckbox-wrapper',
  templateUrl: './multicheckbox-wrapper.component.html',
  standalone: true,
  imports: [NgIf, FormlyModule]
})
export class MulticheckboxWrapperComponent extends FieldWrapper {
  @ViewChild('fieldComponent', { read: ViewContainerRef, static: true }) fieldComponent: ViewContainerRef;
}
